// import AdminLayout from "../../../Layout/AdminLayout";
import RoleForm from "./modules/RoleForm";
import { PageTitle } from "../modules/PageHeaders";

const NewRole = () => {
  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <div className="my-1">
        <PageTitle title={"Create Role"} />
      </div>
      <RoleForm />
    </section>
  );
};

export default NewRole;
