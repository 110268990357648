// import React, { useState } from "react";
import FilterFind from "../../../components/FilterFind";
// import AdminLayout from "../../../Layout/AdminLayout";

import CreateEventCalendar from "./modules/CreateEventCalendar";

export default function EventCalendarCreate() {
  return (
    <section className=" px-lg-3  min-vh-100 pb-5 mb-5">
      <FilterFind title={"Create Event"} rightSide={false} />
      <div className="py-1"></div>
      <CreateEventCalendar onNext={() => {}} />
    </section>
  );
}
