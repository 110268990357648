import React from "react";
import { useLocation } from "react-router-dom";
import { ButtonComp, CardComp, DropDownComp2 } from "../../../components";
import CalendarComp from "../../../components/Common/CalendarComp";
import FallbackImage from "../../../components/Common/ImageWrapper";
import { MemberAttendanceHistoryHeader } from "../../../utils/TableHeader";
import AllCompanyPrefix from "../../Admin/Configuration&Settings/modules/AllCompanyPrefix";
import PageWrapper from "../modules/PageWrapper";

export default function MemberAttendance() {
  const location = useLocation();
  const TableDropDown = [
    {
      name: "I’m available",
      action: (data) => {
        console.log(location);
        // navigate(`${location?.pathname}/${data?.id}`)
      },
    },
    {
      name: "I’m unavailable",
      action: (data) => {
        // navigate(`/super_admin/staff_registration/suspend_staff`)
      },
    },
  ];

  return (
    <PageWrapper mainClassName="" title={"Meetings Attendance"}>
      <section className="row mb-5 g-4">
        <div className="col-lg-7">
          <div className="  rounded-4 mb-5">
            <div className="row">
              <div className="col-md-4 bg-primary1 rounded-4 mb-3 mb-lg-0">
                <div className="">
                  <div className="border py-2 bg-white rounded-pill mt-5 mb-5">
                    <DropDownComp2
                      dropLabelClassName={"w-100 h4 mb-0  fw-bold border-0 "}
                      DropDownText={
                        <div className="d-flex justify-content-center align-items-center">
                          <h4 className="mb-0 fw-bold me-2">February</h4>
                          <span>
                            <FallbackImage src="/MemberSide/Icons/arrowdown.svg" />
                          </span>
                        </div>
                      }
                    />
                  </div>
                  <div>
                    <h3 className="text-center text-white fw-3 mb-5">
                      You have no events to attend this month
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className=" bg-white  rounded-4  ">
                  <CalendarComp outLine height={"318px"} />
                </div>
              </div>
              <div>
                {/* <div className='border px-2 rounded bg-white'>
                                <DropDownComp2
                                dropLabelClassName={"border-0"}
                                DropDownText="All"
                                show
                                />
                            </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <section className="mb-5">
            <div className="d-flex align-items-center py-4 px-3 justify-content-between mb-3 bg-white rounded">
              <div className="d-flex align-items-center  ">
                <div className="me-3">
                  <FallbackImage src="/MemberSide/Icons/dashboard10.svg" />
                </div>
                <div>
                  <h4 className="mb-0">Absent Fines</h4>
                  <div className="h50 opacity-50">NGN 4,000.00</div>
                </div>
              </div>
              <div>
                <ButtonComp
                  btnText={"Pay now"}
                  btnClassName={"bg-primary1 text-white py-1 px-4 rounded"}
                />
              </div>
            </div>
            <div className="d-flex align-items-center px-3 py-4 justify-content-between mb-3 bg-primary1 text-white rounded">
              <div className="d-flex align-items-center  ">
                <div className="me-3">
                  <FallbackImage src="/MemberSide/Icons/dashboard9.svg" />
                </div>
                <div>
                  <h4 className="mb-2">Upcoming Meeting</h4>
                  <div className="h50 mb-1">
                    <FallbackImage src="/MemberSide/Icons/calendar2.svg" />
                    <span className="ms-2 me-3">April 9th, 2023</span>
                    <span>4:30PM</span>
                  </div>
                  <div className="h50 ">
                    <FallbackImage src="/MemberSide/Icons/location.svg" />
                    <span className="ms-2 me-3">Secretariat</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section className="row g-4">
        <div className="col-lg-8">
          <CardComp cardClassName={"border-0 pt-3 px-3"}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5 w-100">
              <h4 className="fw-bold text-black1  mb-3 mb-lg-0">
                Attendance History
              </h4>
              <div className="">
                <div className="d-inline-block border px-3 rounded py-1">
                  <DropDownComp2
                    dropLabelClassName={"border-0 h50 d-inline-block"}
                    show
                    DropDownText={"February, 2022"}
                  />
                </div>
              </div>
            </div>

            <div>
              <AllCompanyPrefix
                tableHeader={MemberAttendanceHistoryHeader(TableDropDown)}
                // tableData={[]}
                marginBottom={"6rem"}
                emptyMessage={
                  <FallbackImage
                    // width='100px'
                    // height='100px'
                    src="/MemberSide/Icons/empty.svg"
                    className="my-4"
                  />
                }
              />
            </div>
          </CardComp>
        </div>
        <div className="col-lg-4">
          <CardComp cardClassName={"border-0 pt-2 pb-3"}>
            <FallbackImage
              width="100%"
              height="146.11px"
              src="/MemberSide/Icons/advert image.svg"
              className="mb-4"
            />
            <div className="d-flex justify-content-center">
              <div className="col-11">
                <h5>Heading</h5>
                <p className="h50 mb-4">
                  Write an amazing description in this dedicated card section.
                  Each word counts...{" "}
                </p>
                <ButtonComp
                  btnText={"Learn more"}
                  btnClassName={"bg-primary1 text-white py-2 px-5 rounded"}
                />
              </div>
            </div>
          </CardComp>
        </div>
      </section>
    </PageWrapper>
  );
}
