import React from "react";
import { ButtonComp } from "../../Ui";
import FallbackImage from "../../Common/ImageWrapper";
import { useNavigate } from "react-router-dom";

export default function CheckEmail({ onNext }) {
  const navigate = useNavigate();
  return (
    <div className="col-xl-11 py-3 bg-white px-4 ">
      <div className="text-center mb-5 pb-5">
        <div
          className="bg-primary1 d-inline-block rounded-2"
          style={{ width: "40px", height: "40px" }}
        ></div>
        <p className="mb-1 fw-bold ">Ifesowapo Cooperative, LTC</p>
        <div className="h50">6&7, Alaka Express, Gbagada, Surulere, Lagos</div>
        {/* <div className="h50">+234 814 0307 009</div> */}
      </div>

      <div className="pt-4 pb-3   container rounded-4 mb-3 text-center ">
        {/* <h4 className="fw-bold mb-2">Check your Email</h4>
      <div className='mb-4 h50 black3'>
      We have sent an email with password reset information to n****e@e***e.com.        </div> 
           <div className='h50 black3'>Didn’t receive the email? Check spam or promotion folder or</div> */}
        <FallbackImage src="/Login/success.png" width={225} />
      </div>

      <div className="mb-5">
        <div></div>
      </div>
      <div className="col-lg-12">
        {/* <div className="fw-3 mt-3 mb-5  pointer">Forget password?</div> */}
        <div className="mb-4">
          <ButtonComp
            btnText={"Proceed to Login"}
            onClick={() => navigate("/login")}
            btnClassName="w-100 bg-primary1 text-white border-0 py-3 rounded-4 fw-semibold"
          />
        </div>
        {/* <div className="mb-4">
          <ButtonComp
          BorderColor={'#8B313A'}
          BorderWidth={'1.5px'}
            btnText={"Back to Login"}
            onClick={{}}
            btnClassName="w-100 text-primary1   py-3 rounded-4 fw-bold"
          />
        </div>
         */}
        {/* <h6 class="background text-center mb-4">
          <span>OR</span>
        </h6> */}
        {/* <div className="text-center mb-5">
          <ButtonComp
            BorderColor={"#8B313A"}
            BorderWidth={'1px'}
            btnClassName={
              "border border-1 rounded border-secondary-1 py-2 px-3 bg-white"
            }
            btnText={
              <span>
                <span className="pe-2">
                  <FcGoogle size={25} />
                </span>
                Continue with Google
              </span>
            }
          />
        </div> */}
        <div>
          {/* <div className="text-center h50 mb-4">
          Don’t have an account?{" "}
          <span className="fw-3 text-1 h40">Sign up</span>
        </div> */}
        </div>
      </div>
    </div>
  );
}
