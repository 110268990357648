import React from "react";
import {
  ButtonComp,
  MoneyInputComp,
  SelectComp2,
  TextInputComp,
} from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import { Formik, Form } from "formik";
import * as Yup from "yup";

export default function LoanApplicationSetting() {
  const validationSchema = Yup.object({
    minGuarantor: Yup.number().required("Incorrect"),
    maxGuarantor: Yup.number().required("Incorrect"),
    feeName: Yup.string().required("Fee name is required"),
  });
  const initialValues = {
    minGuarantor: 0,
    maxGuarantor: 1,
    mainPercent: 0,
    overduePercent: 0,
    feeName: "",
    amount: "",
  };
  return (
    <section className="bg-white mx-2 mx-lg-4 px-4 min-vh-100 pb-5 mb-5">
      <div className="py-4"></div>
      <div className="mb-4">
        <h4 className=" text-black1 fw-bold">{"Loan Settings"}</h4>
      </div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => console.log(values)}
        >
          <Form>
            {/* <h5 className="text-primary1 mb-0">Gurarantor’s count</h5> */}
            {/* <hr /> */}
            {/*  */}
            {/* <section className="row gx-5 gy-3 justify-content-between">
              <div className="col-lg-5">
                <SelectComp2
                  LabelClassName={"fw-2"}
                  labelText={"Guarantor’s type"}
                  placeholder="Select guarantor’s type"
                  arrayComp={[
                    { value: "", label: "Select Location" },
                    {
                      value: "Lagos",
                      label: "Lagos",
                    },
                  ]}
                />
              </div>
              <div className="col-lg-5"></div>
              <div className="col-lg-5">
                <TextInputComp
                  LabelText={"Minimum No. of guarantor"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Input minimum number"}
                  name="minGuarantor"
                  type="number"
                />
              </div>
              <div className="col-lg-5">
                <TextInputComp
                  LabelText={"Maximum No. of  guarantor"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Input maximum number"}
                  name="maxGuarantor"
                  type="number"
                />
              </div>
            </section> */}
            <hr />
            {/*  */}
            <section className="row gx-5 gy-3 justify-content-between">
              <div className="d-flex justify-content-between">
                <h5 className="text-primary1 mb-0">Loan fees</h5>
                <div>
                  <img
                    src="/Staff/plus.svg"
                    alt=""
                    style={{ width: "70%" }}
                    className="opacity-75 pointer"
                  />
                </div>
              </div>

              <div className="col-lg-5">
                <TextInputComp
                  LabelText={"Name of fee"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Input fees name"}
                  name="feeName"
                  type="text"
                />
              </div>
              <div className="col-lg-5">
                <MoneyInputComp
                  LabelText={"Amount"}
                  // borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Input amount"}
                  name={"amount"}
                  handleChange={(change) => (initialValues.amount = change)}
                />
              </div>
            </section>
            <hr />
            {/*  */}
            <section className="row gx-5 gy-3 justify-content-between">
              <h5 className="text-primary1 mb-0">Interest</h5>

              <div className="col-lg-5">
                <TextInputComp
                  LabelText={"Main interest percentage"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Input interest percentage"}
                  name="mainPercent"
                  type="number"
                />
              </div>
              <div className="col-lg-5">
                <TextInputComp
                  LabelText={"Overdue interest percentage"}
                  borderColor="#A1A0A0"
                  LabelClassName={"fw-2"}
                  placeholder={"Input interest percentage"}
                  name="overduePercent"
                  type="number"
                />
              </div>
            </section>
            <hr />
            {/*  */}
            <section className="row gx-5 gy-3 justify-content-between mb-5">
              <h5 className="text-primary1 mb-0">Repayment</h5>

              <div className="col-lg-5">
                <SelectComp2
                  LabelClassName={"fw-2"}
                  labelText={"Repayment method"}
                  placeholder="Select repayment method"
                  arrayComp={[
                    { value: "", label: "Select Location" },
                    {
                      value: "Lagos",
                      label: "Lagos",
                    },
                  ]}
                />
              </div>
            </section>
            <div className="d-flex justify-content-center">
              <div className="col-10 col-lg-6">
                <ButtonComp
                  btnText={"Submit"}
                  btnClassName={"bg-primary1 text-white h5 py-2 w-100 rounded"}
                />
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </section>
  );
}
