import React from "react";

export const Input = (props) => {
  const {
    type,
    value,
    name,
    placeholder,
    onChange,
    required,
    className,
    label,
    span,
    onBlur,
    id,
    error,
    errorMessage,
    readOnly,
    icon,
    borderR,
    loading,
    disableFutureDate=false,
    prefixClass,
  } = props;

  const today = new Date().toISOString().split('T')[0];
  function handleCheckIfDateAndDisbaleFutureData(){
    return type==='date' &&disableFutureDate;
  }

  return (
    <>
      {type === "tel" ? (
        <div className="w-full cursor-text my-[10px] relative">
          {label && <p className="font-[500] mb-1">{label}</p>}
          <div className="w-full h-full cursor-text flex items-center rounded-[5px] relative">
            <p className="pl-[14px] z-[10] absolute text-[14px] leading-[24px] font-circular_medium text-primary flex items-center">
              234 &nbsp;
            </p>
            <div className="w-full relative flex items-center ">
              {/* <p>abeg</p> */}
              <input
                data-testid="input-field"
                type="tel"
                value={value}
                name={name}
                placeholder={placeholder}
               
                onChange={onChange}
                autoComplete="off"
                required={required}
                onBlur={onBlur}
                id={id}
                className={`h-[44px] bg-white cursor-text w-full border pl-[50px] text-[rgba(33,35,55,0.75)] leading-[24px] text-[1rem] outline-none rounded-[5px] placeholder:font-circular_medium placeholder:text-[14px] placeholder:text-[#ACA2C3] focus:border-primary focus:ring-1 focus:ring-primary  disabled:opacity-50  ${
                  error ? "border-red-500" : "border-[#E6EAEE]"
                } ${className}`}
              />
            </div>
          </div>
          {/* formik error text */}
          {(error || errorMessage) && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {errorMessage}
            </span>
          )}
          {/* additional text */}
          {span && (
            <span className="text-primary font-light font-circular_book block text-[12px]">
              {span}
            </span>
          )}
        </div>
      ) : (
        <div className="w-full cursor-text my-[10px] relative ">
          {label && <p className="font-[500] mb-1">{label}</p>}

          <div className="w-full relative">
            <div
              className={`h-10 flex bg-white cursor-text w-full border text-[rgba(33,35,55,0.75)] font-circular_medium leading-[24px] text-sm outline-none rounded-[5px] px-2 placeholder:font-circular_medium placeholder:text-sm placeholder:text-[#ACA2C3] focus:border-primary focus:ring-1 focus:ring-primary  disabled:opacity-50  ${
                error ? "border-red-500" : "border-[#E6EAEE]"
              } ${className}`}
            >
              {icon && (
                <div
                  className={`h-full flex justify-center items-center pr-2 pl-3 ${
                    borderR ? "border-r" : ""
                  } ${prefixClass}`}
                >
                  {icon}
                </div>
              )}

              <input
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                autoComplete="off"
                required={required}
                onBlur={onBlur}
                id={id}
                max={handleCheckIfDateAndDisbaleFutureData()&&today} // Disable future dates
                readOnly={readOnly}
                className={`w-full outline-none ring-0 border-none h-full`}
              />
            </div>

            {loading && (
              <div className="absolute right-5 top-[50%] transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            )}
          </div>
          {/* formik error text */}

          {error && (
            <span className="text-red-500 font-circular_book text-[14px]">
              {errorMessage}
            </span>
          )}

          {/* additional text */}
          {span && (
            <span className="text-primary font-circular_book font-normal text-sm block text-[12px] mt-1">
              {span}
            </span>
          )}
        </div>
      )}
    </>
  );
};

const Loader = () => <div className="loader" />;
