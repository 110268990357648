import React, { useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { RiSearch2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import {
  ButtonComp,
  CardComp,
  DropDownComp,
  ModalCompAdvance,
  TableCompData,
} from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import FallbackImage from "../../../../components/Common/ImageWrapper";
import { ContributionBreakDownHeader } from "../../../../utils/TableHeader";
import {
  useGetMembersContributionHistoryByCategoryIdQuery,
  useSearchContributionRecordsQuery,
} from "../../../../store/admin/contribution";
import TablePagination from "../../../../components/Table/Pagination";

export const ContributionBreakdown = ({
  title,
  TableDropDown,
  modalPage,
  setModalToggle,
  useModal,
  modalToggle,
}) => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const { id } = useParams();

  const { data, isFetching } =
    useGetMembersContributionHistoryByCategoryIdQuery({
      categoryId: id,
      page: pageNumber,
    });

  const { data: searchResults = {}, isFetching: isSearching } =
    useSearchContributionRecordsQuery({ page: pageNumber, search: searchTerm });

  const tableData = searchTerm
    ? searchResults?.contributions
    : data?.data;

  const pagination = searchTerm
    ? searchResults?.pagination
    : data?.data?.pagination;

  const onSearch = (e) => {
    setPageNumber(1);
    setSearchTerm(e);
  };

  // console.log(tableData,TableDropDown,'datadatadata')

  return (
    <section className="  px-lg-4 min-vh-100 pb-5 mb-3">
      <CardComp
        cardClassName={"border-0 py-0 mb-3"}
        cardBodyClassName="px-lg-4"
      >
        <div className="row justify-content-between align-items-center mb-0 flex-wrap">
          <div className="d-flex col-lg-6 mb-3 mb-lg-0 items-center">
            <BsArrowLeft
              size={30}
              onClick={() =>
                navigate(`/super_admin/contribution/contribution_history`)
              }
              className="me-3 pointer"
            />
            <h4 className="fw-bolder mb-0 text-black1">
              Contribution Breakdown
            </h4>
          </div>
          <div className="col-lg-6">
            <div class="input-group  search me-3">
              <span className="input-group-text bg-black2 px-4 py-0 my-0">
                <RiSearch2Line size={25} color={"#fff"} />
              </span>
              <input
                type="text"
                className="form-control py-3 bg-gary3"
                placeholder="Search your query here"
                onKeyUp={(event) => onSearch(event.target.value)}
              />
            </div>
          </div>
        </div>
      </CardComp>

      <CardComp cardClassName={"border-0"} cardBodyClassName="px-lg-4">
        <div className="py-3"></div>
        <div className="mb-3 d-flex justify-content-end">
          <div className=" d-flex align-items-center gap-3">
            <div className="bg-secondary3 px-3 h50 rounded">
              All transaction
            </div>
            <DropDownComp
              dropLabelClassName={"border-0"}
              DropDownText={
                <span className="pointer">
                  <FallbackImage
                    src="/Icon/filter.png"
                    width={40}
                    height={40}
                    alt="filter"
                  />
                </span>
              }
              arrayComp={[
                {
                  name: (
                    <div disabled="true" className="pe-4 ">
                      {" "}
                      <span className="me-3">
                        <img src="/Icon/filernormal.svg" alt="filter" />
                      </span>
                      Filter
                    </div>
                  ),
                  aClassName: "bg-white1 text-black",
                },
                {
                  name: <span className="pe-4">All transactions</span>,
                },
                {
                  name: <span className="pe-4">Successful transactions</span>,
                },
                {
                  name: <span className="pe-4">Failed transactions</span>,
                },
              ]}
              input="name"
            />
          </div>
        </div>
        <main>
          <div className="mb-5">
            <TableCompData
              columns={ContributionBreakDownHeader(TableDropDown)}
              data={tableData}
              message={
                isFetching || isSearching ? (
                  <div className=" py-4 ">
                    <h3>Loading...</h3>
                  </div>
                ) : (
                  <EmptyTable
                    Message={
                      <h3 className="text-muted pb-4">
                        There are no contribution history
                      </h3>
                    }
                    btnText={"Record Contribution"}
                    onClick={() =>
                      navigate(`/super_admin/contribution/record_contribution`)
                    }
                  />
                )
              }
              marginBottom="50px"
            />
          </div>
          <div></div>
        </main>
      </CardComp>
      <div className="py-1"></div>
      <div className="mt-3">
        <p className="text-blacklight mb-3 fw-semibold">
          Download contribution breakdown
        </p>
        <div className="d-flex">
          <ButtonComp
            btnText={"Download CSV"}
            btnClassName={
              "py-3 h6 bg-primary1 border-0 text-white rounded px-5 me-4 "
            }
          />
          <ButtonComp
            btnText={"Download PDF"}
            btnClassName={
              "py-3 h6 bg-primary1 border-0 text-white rounded px-5 "
            }
          />
        </div>
      </div>
      {tableData?.length > 0 && (
        <TablePagination {...pagination} handlePageChange={setPageNumber} />
      )}
      <ModalCompAdvance
        modalBody={modalPage}
        show={modalToggle}
        arrayComp={useModal}
        handleClose={() => setModalToggle(false)}
        centered
        size={"lg"}
      />
    </section>
  );
};
