import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonComp, CardComp, DropDownComp2 } from "../../../components";
import FallbackImage from "../../../components/Common/ImageWrapper";
import { formatMoney } from "../../../utils";
import { MemberContributionData } from "../../../utils/dummbyData";
import { MemberContributionsHistory } from "../../../utils/TableHeader";
import AllCompanyPrefix from "../../Admin/Configuration&Settings/modules/AllCompanyPrefix";
import CardInfo from "../../Admin/Home/components/CardInfo";
import PageWrapper from "../modules/PageWrapper";
import SearchBar from "../modules/SearchBar";

export default function MemberContribution() {
  const navigate = useNavigate();
  const location = useLocation();

  const TableDropDown = [
    {
      name: "Pay now",
      action: (data) => {
        console.log(location);
        navigate(`${location?.pathname}/${data?.id}`);
      },
    },
    {
      name: "Record payment",
      action: (data) => {
        navigate(`/super_admin/staff_registration/suspend_staff`);
      },
    },
    {
      name: "Query transaction",
      action: (data) => {
        navigate(`/super_admin/staff_registration/change_branch`);
      },
    },
  ];

  return (
    <>
      <PageWrapper title={"My Contribution"}>
        <section className="row mb-5 g-4">
          <div className="col-lg-7">
            <div className="bg-primary1 p-4 rounded-4 mb-5">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-12 col-md-8 bg-white  rounded-4  mb-3">
                  <CardInfo
                    total={formatMoney("0", true, "₦")}
                    name={"Amount Contributed"}
                    percentage={"50%"}
                    image={"/MemberSide/Icons/dashboard1.svg"}
                    bgColor={"#A8E1C8"}
                    color={"#318C64"}
                  />
                </div>
                <div>
                  <div className="border px-2 rounded bg-white">
                    <DropDownComp2
                      dropLabelClassName={"border-0"}
                      DropDownText="All"
                      show
                    />
                  </div>
                </div>
              </div>
              <p className="text-white fw-0 col-lg-9 mb-2">
                You can create personal contribution to help you achieve a set
                target
              </p>
              <ButtonComp
                btnText={"Create contribution"}
                onClick={() => navigate("/member/contribution/create")}
                btnClassName="text-white bg-default rounded col-8 col-lg-5 py-3"
                BorderColor={"#fff"}
                BorderWidth={"2px"}
              />
            </div>
          </div>
          <div className="col-lg-5">
            <section className="mb-5">
              {MemberContributionData()?.map((item, i) => (
                <div className="mb-4 rounded-4 shadow3" key={i}>
                  <CardInfo
                    total={item?.total}
                    name={item?.name}
                    contribution={item?.contributions}
                    key={i}
                    image={item?.image}
                    bgColor={item?.bgColor}
                    color={item?.color}
                    // arrow
                    onclick={item?.onclick}
                  />
                </div>
              ))}
            </section>
          </div>
        </section>
        <section>
          <CardComp cardClassName={"border-0 pt-3 px-3"}>
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
              <h4 className="fw-bold text-black1  mb-3 mb-lg-0">
                Contributions History
              </h4>
              <div className="col-lg-6">
                <SearchBar placeholder={"Search your query here"} />
              </div>
            </div>
            <div className="mb-4 fw-2 text-end d-flex align-items-center justify-content-end">
              <div
                className="px-3 px-lg-5 rounded-pill py-2 d-inline-block me-3"
                style={{
                  background: "rgba(49, 119, 140, 0.1)",
                  color: "#31778C",
                }}
              >
                All transactions
              </div>
              <span>
                <FallbackImage
                  src="/Icon/filter.svg"
                  width="40px"
                  height="40px"
                />
              </span>
            </div>
            <div>
              <AllCompanyPrefix
                tableHeader={MemberContributionsHistory(TableDropDown)}
                // tableData={[]}
                marginBottom={"6rem"}
                emptyMessage={
                  <FallbackImage
                    // width='100px'
                    // height='100px'
                    src="/MemberSide/Icons/empty.svg"
                    className="my-4"
                  />
                }
              />
            </div>
          </CardComp>
        </section>
      </PageWrapper>
    </>
  );
}
