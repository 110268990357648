import { API, ApiPrefixes } from "../api";

export const ContributionApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getMembersContributionHistory: builder.query({
      query: (page) => ({
        url:
          ApiPrefixes["contribution"] +
          `/contribution-history?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "member-contributions-history", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),

    getMembersContributionHistoryByCategoryId: builder.query({
      query: ({ categoryId, page }) => ({
        url:
          ApiPrefixes["contribution"] +
          `/${categoryId}/pull/all?guard=staff&page=${page}&limit=20`,

          // `/${categoryId}/breakdown/all?guard=staff&page=${page}&limit=20`,
      }),
      providesTags: (result, error, arg) => [
        { type: "member-contributions-history-by-category-id", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),

    createMemberContribution: builder.mutation({
      query: (body) => ({
        url: ApiPrefixes["contribution"] + `/create?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "member-contributions-history" },
        { type: "member-contributions-history-by-category-id" },
      ],
    }),

    searchContributionRecords: builder.query({
      query: ({ search, page }) => ({
        url:
          ApiPrefixes["contribution"] +
          `/search/all?guard=staff&page=${page}&limit=20&search=${search}`,
      }),
      transformResponse: (responseData) => {
        return responseData.data;
      },
    }),

    //Contribution under settings

    getContributionCategories: builder.query({
      query: () => ({
        url:
          ApiPrefixes["adminSettings"] +
          `/contributions_categories?guard=staff&page=1&limit=20`,
      }),
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, arg) => [
        { type: "contribution-category", id: arg },
      ],
      cacheStrategy: "cache-and-network",
    }),

    createContributionCategory: builder.mutation({
      query: (body) => ({
        url:
          ApiPrefixes["adminSettings"] +
          `/contributions_categories/create?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        { type: "member-contributions-history" },
        { type: "contribution-category" },
      ],
    }),

    archiveContributionCategory: builder.mutation({
      query: (categoryId) => ({
        url:
          ApiPrefixes["adminSettings"] +
          `/contributions_categories/${categoryId}/archive?guard=staff`,
        method: "PUT",
        // body: body,
      }),
      invalidatesTags: [
        { type: "member-contributions-history" },
        { type: "contribution-category" },
      ],
    }),

    updateContributionCategory: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["adminSettings"] +
          `/contributions_categories/${credential.id}/update?guard=staff`,
        method: "PATCH",
        body: credential,
      }),
      invalidatesTags: [
        { type: "member-contributions-history" },
        { type: "contribution-category" },
      ],
    }),
    deleteContributionCategory: builder.mutation({
      query: (credential) => ({
        url:
          ApiPrefixes["adminSettings"] +
          `/contributions_categories/${credential.id}/archive?guard=staff`,
        method: "PUT",
        body: credential.payload,
      }),
      invalidatesTags: [
        { type: "member-contributions-history" },
        { type: "contribution-category" },
      ],
    }),
  }),
});

export const {
  useGetMembersContributionHistoryQuery,
  useGetMembersContributionHistoryByCategoryIdQuery,
  useSearchContributionRecordsQuery,
  useCreateMemberContributionMutation,
  useGetContributionCategoriesQuery,
  useCreateContributionCategoryMutation,
  useArchiveContributionCategoryMutation,
  useUpdateContributionCategoryMutation,
  useDeleteContributionCategoryMutation,
} = ContributionApi;
