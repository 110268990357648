/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { ButtonComp, TextInputComp } from "../../Ui";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form } from "formik";

export default function ResetPasswordForm({ setPageName }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  // Yup validations schema
  const validationSchema = Yup.object({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().required("Password is required"),
  });

  async function handleResetPassword(values) {
    // setPageName("Failed");
    setShowModal(true);
  }

  const initialSeconds = 2 * 60 + 30;
  const [seconds, setSeconds] = useState(initialSeconds);
  // const [seconds, setSeconds] = useState(5);
  const [showModalsOtp, setShowModalsOtp] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const handleClick = () => {
    setShowModalsOtp(true);
    setSeconds(initialSeconds);
    // setSeconds(5);
  };

  const handleClose = () => {
    setShowModalsOtp(false);
  };

  return (
    <div className="bg-white py-10 px-10 rounded-3xl flex flex-col relative">
      <div className="absolute -top-12 -right-5">
        <svg
          width="34"
          height="32"
          viewBox="0 0 34 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0001 0.200348L20.8884 12.1674H33.4713L23.2915 19.5635L27.1799 31.5305L17.0001 24.1345L6.82026 31.5305L10.7086 19.5635L0.528801 12.1674H13.1117L17.0001 0.200348Z"
            fill="#8B313A"
          />
        </svg>
      </div>
      <div className="absolute top-0 -right-10">
        <svg
          width="24"
          height="22"
          viewBox="0 0 24 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.7024 0.128797L14.3185 21.944L0.117944 2.90972L23.7024 0.128797Z"
            fill="#B13E4A"
          />
        </svg>
      </div>
      {/* <div className="text-center mb-5 pb-5">
        <div
          className="bg-primary1 d-inline-block rounded-2"
          style={{ width: "40px", height: "40px" }}
        ></div>
        <p className="mb-1 fw-bold ">Ifesowapo Cooperative, LTC</p>
        <div className="h50">6&7, Alaka Express, Gbagada, Surulere, Lagos</div>
        <div className="h50">+234 814 0307 009</div>
      </div> */}
      <div className="flex flex-col items-center bg-white rounded-3xl text-center lg:hidden">
        <img
          src="../Images/Login/sign-up-image.png"
          alt="profile"
          className="w-[100px] h-[100px]"
        />
        <p className="font-[700] text-[32px]">Ifesowapo Cooperative, LTC</p>
        <p className="text-[#858585] text-lg">
          6&7, Alaka Express, Gbagada, Surulere, Lagos
        </p>
      </div>
      <div className="text-left">
        <h2 className="font-[700] mb-2 text-[24px]">Reset Password</h2>
        <div className="">Create new password for your cooperative login </div>
      </div>
      <div className="mb-3">
        <div></div>
      </div>

      <Modal show={showModal} centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-5">
          <button onClick={() => setShowModal(false)} className="">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 2.0997L13.09 0.689697L7.5 6.2797L1.91 0.689697L0.5 2.0997L6.09 7.6897L0.5 13.2797L1.91 14.6897L7.5 9.0997L13.09 14.6897L14.5 13.2797L8.91 7.6897L14.5 2.0997Z"
                fill="black"
              />
            </svg>
          </button>
          <div className="flex flex-col gap-3 items-center justify-center">
            <div className="text-[100px]">
              <svg
                width="100"
                height="101"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5705_35917)">
                  <path
                    d="M100 50.5C100 78.1139 77.6139 100.5 50 100.5C22.3861 100.5 0 78.1139 0 50.5C0 22.8861 22.3861 0.5 50 0.5C77.6139 0.5 100 22.8861 100 50.5Z"
                    fill="#FFCC4D"
                  />
                  <path
                    d="M49.9999 61.1917C41.761 61.1917 36.2916 60.2306 29.5305 58.9167C27.9832 58.6222 24.9805 58.9167 24.9805 63.4667C24.9805 72.5639 35.4332 83.9361 49.9999 83.9361C64.5666 83.9361 75.0194 72.5639 75.0194 63.4667C75.0194 58.9167 72.0138 58.6194 70.4694 58.9167C63.7082 60.2306 58.2416 61.1917 49.9999 61.1917Z"
                    fill="#664500"
                  />
                  <path
                    d="M46.25 9.61389C45.3381 7.023 43.6267 4.78879 41.3627 3.23361C39.0987 1.67842 36.3992 0.882671 33.6537 0.961168C30.9081 1.03967 28.2585 1.98835 26.087 3.67035C23.9156 5.35235 22.3346 7.68069 21.5723 10.3194C19.8849 9.1375 17.94 8.37482 15.899 8.09464C13.858 7.81445 11.7796 8.02483 9.83614 8.70833C6.59343 9.85371 3.93824 12.2399 2.45424 15.3423C0.970234 18.4446 0.778886 22.0094 1.92225 25.2528C2.26114 26.2083 2.71114 27.0944 3.23337 27.9139C8.65281 37.8639 24.3111 44.8583 33.7917 44.9444C41.1167 38.925 48.9195 23.6528 46.8973 12.5083C46.7919 11.5221 46.5747 10.5511 46.25 9.61389ZM53.75 9.61389C54.8949 6.37067 57.2808 3.71483 60.3832 2.23028C63.4857 0.745724 67.0507 0.553988 70.2945 1.69722C72.2382 2.38447 73.9902 3.5251 75.4051 5.02456C76.82 6.52403 77.8572 8.33914 78.4306 10.3194C80.118 9.1375 82.0628 8.37482 84.1038 8.09464C86.1448 7.81445 88.2232 8.02483 90.1667 8.70833C93.4095 9.85377 96.0648 12.2398 97.5493 15.342C99.0337 18.4443 99.2258 22.009 98.0834 25.2528C97.7475 26.1867 97.3067 27.0794 96.7695 27.9139C91.35 37.8639 75.6917 44.8583 66.2084 44.9444C58.8834 38.925 51.0834 23.6528 53.1056 12.5083C53.2084 11.5417 53.4167 10.5694 53.75 9.61389Z"
                    fill="#DD2E44"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5705_35917">
                    <rect
                      width="100"
                      height="100"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className="font-[700] text-[24px] text-center flex flex-col md:gap-2 md:flex-row">
              <p>Password Reset Successful</p>
              <div className="flex justify-center md:mt-[5px]">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 23.25C6.07 23.25 1.25 18.43 1.25 12.5C1.25 6.57 6.07 1.75 12 1.75C17.93 1.75 22.75 6.57 22.75 12.5C22.75 18.43 17.93 23.25 12 23.25ZM12 3.25C6.9 3.25 2.75 7.4 2.75 12.5C2.75 17.6 6.9 21.75 12 21.75C17.1 21.75 21.25 17.6 21.25 12.5C21.25 7.4 17.1 3.25 12 3.25Z"
                    fill="#19BC31"
                  />
                  <path
                    d="M10.5804 16.0801C10.3804 16.0801 10.1904 16.0001 10.0504 15.8601L7.22043 13.0301C6.93043 12.7401 6.93043 12.2601 7.22043 11.9701C7.51043 11.6801 7.99043 11.6801 8.28043 11.9701L10.5804 14.2701L15.7204 9.1301C16.0104 8.8401 16.4904 8.8401 16.7804 9.1301C17.0704 9.4201 17.0704 9.9001 16.7804 10.1901L11.1104 15.8601C10.9704 16.0001 10.7804 16.0801 10.5804 16.0801Z"
                    fill="#19BC31"
                  />
                </svg>
              </div>
            </p>
            <p className="text-[#858585] text-center">
              Lorem ipsum dolor sit amet consectetur. Diam habitasse est enim
              etiam mauris.
            </p>
            <button
              className="bg-[#8B313A] w-full py-3 rounded-2xl text-[16px] text-white font-[600]"
              onClick={() => navigate("/reset_password")}
            >
              Proceed to login
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleResetPassword}
      >
        <Form>
          <div className="col-lg-11 w-full">
            <div className="mb-2">
              <TextInputComp
                LabelClassName={"fw-normal"}
                borderColor={"#A1A0A0"}
                borderWidth="1px"
                InputClassName={"border  border-1 py-2"}
                labelText={"OTP"}
                placeholder="Enter OTP code"
                type="number"
                name="otp"
              />
            </div>
            <div className="mb-3">
              <a
                href={seconds === 0 ? "" : undefined}
                className={`text-[#858585] hover:text-red-500 ${
                  seconds === 0 ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                onClick={seconds === 0 ? handleClick : undefined}
              >
                Resend OTP{" "}
                <span className="text-[#FF4343]">
                  {`${Math.floor(seconds / 60)
                    .toString()
                    .padStart(2, "0")}:${(seconds % 60)
                    .toString()
                    .padStart(2, "0")}`}
                </span>
              </a>

              <Modal show={showModalsOtp} onHide={handleClose} centered>
                {/* <Modal.Header closeButton>
                  <Modal.Title>OTP Resent!</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>OTP Resent Successfully!</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="text-white bg-black"
                  >
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="mb-2">
              <TextInputComp
                LabelClassName={"fw-normal"}
                borderColor={"#A1A0A0"}
                borderWidth="1px"
                InputClassName={"border  border-1 py-2"}
                labelText={"New Password"}
                placeholder="Enter Password"
                type="password"
                name="newPassword"
              />
            </div>
            <div className="mb-5">
              <TextInputComp
                LabelClassName={"fw-normal"}
                borderColor={"#A1A0A0"}
                borderWidth="1px"
                InputClassName={"border  border-1 py-2"}
                labelText={"Confirm Password"}
                placeholder="Enter Password"
                type="password"
                name="confirmPassword"
              />
            </div>
            {/* <div className="fw-3 mt-3 mb-5  pointer">Forget password?</div> */}
            <div className="mb-3">
              <ButtonComp
                btnText={"Reset Password"}
                type="submit"
                btnClassName="w-100 bg-primary1 text-white border-0 py-3 rounded-4 fw-semibold"
              />
            </div>
            <div className="mb-4">
              <ButtonComp
                BorderColor={"#8B313A"}
                BorderWidth={"1.5px"}
                btnText={"Back to Login"}
                onClick={() => navigate("/login")}
                btnClassName="w-100 text-primary1   py-3 rounded-4 fw-bold"
              />
            </div>

            {/* <h6 class="background text-center mb-4">
          <span>OR</span>
        </h6> */}
            {/* <div className="text-center mb-5">
          <ButtonComp
            BorderColor={"#8B313A"}
            BorderWidth={'1px'}
            btnClassName={
              "border border-1 rounded border-secondary-1 py-2 px-3 bg-white"
            }
            btnText={
              <span>
                <span className="pe-2">
                  <FcGoogle size={25} />
                </span>
                Continue with Google
              </span>
            }
          />
        </div> */}
            <div>
              {/* <div className="text-center h50 mb-4">
          Don’t have an account?{" "}
          <span className="fw-3 text-1 h40">Sign up</span>
        </div> */}
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
