import React, { useEffect, useState } from "react";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const target = new Date(targetDate);
    const difference = target - now;
    let timeLeft = {};

    if (difference > 0) {
      const years = target.getFullYear() - now.getFullYear();
      const months = target.getMonth() - now.getMonth() + years * 12;
      const days = target.getDate() - now.getDate();

      if (days < 0) {
        const lastMonth = new Date(target.getFullYear(), target.getMonth(), 0);
        timeLeft.days = days + lastMonth.getDate();
        timeLeft.months = months - 1;
      } else {
        timeLeft.days = days;
      }

      if (months < 0) {
        timeLeft.months = months + 12;
        timeLeft.years = years - 1;
      } else {
        timeLeft.months = months;
        timeLeft.years = years;
      }
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  const timerComponents = [];

  if (timeLeft.years) {
    timerComponents.push(
      <span key="years">
        {timeLeft.years} {timeLeft.years === 1 ? "year" : "years"}{" "}
      </span>
    );
  }
  if (timeLeft.months) {
    timerComponents.push(
      <span key="months">
        {timeLeft.months} {timeLeft.months === 1 ? "month" : "months"}{" "}
      </span>
    );
  }
  if (timeLeft.days) {
    timerComponents.push(
      <span key="days">
        {timeLeft.days} {timeLeft.days === 1 ? "day" : "days"}{" "}
      </span>
    );
  }

  return (
    <>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</>
  );
};

export default Countdown;
