import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCalendar3 } from "react-icons/bs";
// import { RiSearch2Line } from "react-icons/ri";
import {
  BarChartComp,
  ButtonComp,
  CardComp,
  DropDownComp,
  // PageSwitch,
  TabsComp,
} from "../../../../components";
import { DashboardCards } from "../../../../utils";
import CardInfo from "./CardInfo";
import { GoPrimitiveDot } from "react-icons/go";
import FallbackImage from "../../../../components/Common/ImageWrapper";
import ActivitiesRow from "./ActivitiesRow";

// import { store } from "../../../../store";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../store/auth";
import { useDashboardQuery } from "../../../../store/admin/dashboard/dashboardSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown from "./Countdown";

export default function HomeComp() {
  const userData = useSelector(selectCurrentUserData);
  const firstName = userData.name.split(" ")[0];
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const [selectedPeriod, setSelectedPeriod] = useState(
    searchParams.get("mode") ?? "Weekly"
  );
  const { data, isFetching } = useDashboardQuery(selectedPeriod === "Weekly");
  const dashboardData = data ? data.data : null;

  console.log(dashboardData,'dashboardData')

  useEffect(() => {
    searchParams.set("mode", selectedPeriod);
    // search.set('upcoming', "today")
    navigate({
      hash: location.hash,
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  const useTab = [
    {
      name: "Loans",
      component: (
        <div className="mb-5 px-3">
          {dashboardData?.loans.map((item, i) => (
            <ActivitiesRow item={item} key={i} />
          ))}
        </div>
      ),
    },
    {
      name: (
        <span>
          <span className="d-block d-md-none">Contr.</span>
          <span className="d-none d-md-block">Contributions</span>
        </span>
      ),
      component: (
        <div className="mb-5 px-3">
          {dashboardData?.contributions.map((item, i) => (
            <ActivitiesRow item={item} key={i} />
          ))}
        </div>
      ),
    },
    {
      name: (
        <span>
          <span className="d-block d-md-none">Recent</span>
          <span className="d-none d-md-block">Recent Transactions</span>
        </span>
      ),
      component: (
        <div className="mb-5 px-3">
          {dashboardData?.recentTransactions.map((item, i) => (
            <ActivitiesRow item={item} key={i} />
          ))}
        </div>
      ),
    },
  ];

  return (
    <main className="mx-2 mx-lg-4 animate__animated animate__fadeIn mb-5">
      {dashboardData ? (
        <>
          <section className="row mt-5 mb-4 align-items-center">
            <div className="col-lg-6">
              <h3 className="fw-bold text-2xl mb-2 text-[#1E1E1E]">
                Welcome {firstName} ✨
              </h3>
              <p className="h50 fw-2 text-sm">
                Here’s the overview of your cooperative activities
              </p>
            </div>
          </section>

          <section className="row g-4 mb-4">
            {DashboardCards(dashboardData).map((item, i) => (
              <div key={i} className="col-lg-4">
                <CardInfo
                  total={item?.total}
                  name={item?.name}
                  key={i}
                  image={item?.image}
                  bgColor={item?.bgColor}
                  color={item?.color}
                  percentage={item?.percentage}
                  route={item?.route}
                  onclick={item?.route}
                />
              </div>
            ))}
          </section>

          <section className="row">
            <div className="col-lg-7">
              <CardComp cardClassName={"border-0 pt-3 mb-3"}>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold">Statistics</h5>
                  <DropDownComp
                    borderColor={"#2125291a"}
                    arrayComp={[
                      {
                        name: <span className="h50">Weekly</span>,
                        action: () => {
                          setSelectedPeriod("Weekly");
                        },
                      },
                      {
                        name: <span className="h50">Monthly</span>,
                        action: () => {
                          setSelectedPeriod("Monthly");
                        },
                      },
                    ]}
                    DropDownText={
                      <span className="h50 text-muted">{selectedPeriod}</span>
                    }
                    show
                    dropLabelClassName="border text-black-50 py-1 px-2"
                    ItemClassName="pe-5 text-2"
                    input="name"
                  />
                </div>
                <div className="d-flex gap-3">
                  <div className="d-flex align-items-center h60">
                    <GoPrimitiveDot
                      color="#8B313A"
                      className="me-1"
                      size={15}
                    />
                    Credit
                  </div>
                  <div className="d-flex align-items-center h60">
                    <GoPrimitiveDot
                      color="#82C1D3"
                      className="me-1"
                      size={15}
                    />
                    Debit
                  </div>
                </div>
                <div>
                  {!isFetching ? (
                    <BarChartComp
                      chartData={dashboardData.statistics.map((stat) => ({
                        name:
                          selectedPeriod === "Weekly" ? stat.week : stat.month,
                        ...stat,
                      }))}
                    />
                  ) : (
                    /*<div>loading ...</div>*/
                    <svg
                      class="pl"
                      width="240"
                      height="240"
                      viewBox="0 0 240 240"
                    >
                      <circle
                        class="pl__ring pl__ring--a"
                        cx="120"
                        cy="120"
                        r="105"
                        fill="none"
                        stroke="#000"
                        stroke-width="20"
                        stroke-dasharray="0 660"
                        stroke-dashoffset="-330"
                        stroke-linecap="round"
                      ></circle>
                      <circle
                        class="pl__ring pl__ring--b"
                        cx="120"
                        cy="120"
                        r="35"
                        fill="none"
                        stroke="#000"
                        stroke-width="20"
                        stroke-dasharray="0 220"
                        stroke-dashoffset="-110"
                        stroke-linecap="round"
                      ></circle>
                      <circle
                        class="pl__ring pl__ring--c"
                        cx="85"
                        cy="120"
                        r="70"
                        fill="none"
                        stroke="#000"
                        stroke-width="20"
                        stroke-dasharray="0 440"
                        stroke-linecap="round"
                      ></circle>
                      <circle
                        class="pl__ring pl__ring--d"
                        cx="155"
                        cy="120"
                        r="70"
                        fill="none"
                        stroke="#000"
                        stroke-width="20"
                        stroke-dasharray="0 440"
                        stroke-linecap="round"
                      ></circle>
                    </svg>
                  )}
                </div>
              </CardComp>
              <CardComp cardClassName={"border-0 pt-3 mb-3"}>
                <div className="mb-5 d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold">Recent Activities</h5>
                  <FallbackImage src="/Icon/filterblue.svg" />
                </div>
                <div>
                  <TabsComp
                    lineHeight={"2px"}
                    TabArray={useTab}
                    borderWidth="3px"
                    lineBottom={"24px"}
                    input="name"
                  />
                </div>
              </CardComp>
            </div>
            <div className="col-lg-5">
              <CardComp cardClassName={"border-0 pt-3 mb-3"}>
                <div className="">
                  <div className="flex justify-between border-b mb-2 pb-2">
                    <h5 className="bg-[#FAFAFA] p-2 rounded-2xl">
                      SMS Balance
                    </h5>
                  </div>
                  <div className="flex flex-col gap-3 items-center text-center">
                    <p>Your Remaining SMS Balance</p>
                    <p className="bg-[#FAFAFA] p-2 text-2xl font-[700] rounded-lg">
                      {dashboardData.smsBalance}
                    </p>
                    <p>
                      You can top up your sms balance by clicking the "Top up
                      SMS" button below
                    </p>
                    <div>
                      <button
                        className="bg-[#8B313A] text-white font-[600] px-4 py-2 rounded-lg"
                        onClick={() => alert("Coming soon")}
                      >
                        Top up SMS
                      </button>
                    </div>
                  </div>
                </div>
              </CardComp>
              <CardComp cardClassName={"border-0 pt-3 mb-3"}>
                <div className="">
                  <div className="flex justify-between items-center border-b mb-2 pb-2">
                    <h5 className="bg-[#FAFAFA] p-2 rounded-2xl">
                      {dashboardData.subscription.plan.name} Plan
                    </h5>
                    {dashboardData.subscription.upgradable && (
                      <p className="text-[#8B313A] cursor-pointer">Upgrade</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-3 items-center text-center">
                    <p>Subscription Expires In</p>
                    <p className="bg-[#FAFAFA] p-2 text-2xl font-[700] rounded-lg">
                      <Countdown
                        targetDate={dashboardData.subscription.expires_at}
                      />
                    </p>
                    <p>
                      You can renew your subscription by clicking the renew
                      button
                    </p>
                    <div>
                      <button
                        className="bg-[#8B313A] text-white font-[600] px-4 py-2 rounded-lg"
                        onClick={() => alert("Coming soon")}
                      >
                        Renew
                      </button>
                    </div>
                  </div>
                </div>
              </CardComp>
              <CardComp cardClassName={"border-0 pt-3 mb-3 h-[40vh]  overflow-y-scroll"}>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <h5 className="text-[21px] text-[#1E1E1E] font-[700]">
                    Upcoming Meetings
                  </h5>
                  <DropDownComp
                    borderColor={"#2125291a"}
                    DropDownText={<span className="h50 text-muted">Today</span>}
                    show
                    dropLabelClassName="border text-black-50 py-1 px-2 "
                  />
                </div>
                {dashboardData?.upcomingMeetings.map((meeting, i) => (
                  <div key={i}>
                    {/* {console.log(dashboardData?.upcomingMeetings,'dashboardData?.upcomingMeetings')} */}
                    <p className="text-muted mb-3">
                      {meeting?.name}
                    </p>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="h60 d-flex gap-5">
                        <span className="d-flex me-2">
                          {" "}
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="mr-4 d-flex justify-content-center align-items-center rounded-circle bg-success3"
                          >
                            <BsCalendar3 color="#292D32" />
                          </div>
                          {meeting?.start_date}
                        </span>
                        <span className="d-flex">
                          <div
                            style={{ width: "20px", height: "20px" }}
                            className="mr-4 d-flex justify-content-center align-items-center rounded-circle bg-success3"
                          >
                            <AiOutlineClockCircle color="#292D32" />
                          </div>
                          {meeting?.start_time} -  {meeting?.end_time}
                        </span>
                      </div>
                      <div>
                        <img src="/Images/Dashboard/edit.svg" alt="" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <img src="/Images/Dashboard/images.svg" alt="" />
                      </div>
                      <ButtonComp
                      containerClassName={'w-auto'}
                        btnText={"Send Reminder"}
                        btnClassName={
                          "px-3 px-lg-5 font-2 py-2 py-lg-3 text-white bg-primary1 border-0 rounded"
                        }
                      />
                    </div>
                  </div>
                ))}
              </CardComp>
            </div>
          </section>
        </>
      ) : (
        // <div>Loading...</div>
        <div >
          <div
            aria-label="Orange and tan hamster running in a metal wheel"
            role="img"
            class="wheel-and-hamster"
          >
            <div class="wheel"></div>
            <div class="hamster">
              <div class="hamster__body">
                <div class="hamster__head">
                  <div class="hamster__ear"></div>
                  <div class="hamster__eye"></div>
                  <div class="hamster__nose"></div>
                </div>
                <div class="hamster__limb hamster__limb--fr"></div>
                <div class="hamster__limb hamster__limb--fl"></div>
                <div class="hamster__limb hamster__limb--br"></div>
                <div class="hamster__limb hamster__limb--bl"></div>
                <div class="hamster__tail"></div>
              </div>
            </div>
            <div class="spoke"></div>
          </div>
        </div>
      )}
    </main>
  );
}
