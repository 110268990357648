import { useState } from "react";
import { ButtonComp, CardComp, PageTab, Loading } from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import Payment from "./modules/Payment";
import PersonalDetails from "./modules/PersonalDetails";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useCreateMemberMutation,
  useGenerateMemberIdQuery,
} from "../../../store/admin/memberManagement/memberSlice";
import { toast } from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
import { selectCurrentUserData } from "../../../store/auth";
import { useSelector } from "react-redux";
import { FormikCheckBtn, personal_details } from "./modules/FormikCheckBtn";
import { useGetCompulsoryFeeQuery } from "../../../store/admin/settings/regFeeSlice";
import uploadImage from "../../../utils/uploadImage";

export default function MemberRegHome() {
  const userData = useSelector(selectCurrentUserData);
  const branchId = userData?.branch_id;
  const {
    data: memberId = "",
    isFetching: isGeneratingId,
    refetch: refetchMemberId,
  } = useGenerateMemberIdQuery(branchId);
  const { data: regAmount = {}, isLoading: isLoadingFee } =
    useGetCompulsoryFeeQuery();
  const { total: amount = 0, break_down = [] } = regAmount;
  const payment = break_down.reduce((prev, fee) => {
    return prev + " " + fee?.name;
  }, "");
  // const navigate = useNavigate();
  const [pageName, setPageName] = useState("personal_details");
  // let pageSelectedArray = [];

  const [createMember] = useCreateMemberMutation();
  const [imgFile, setImgFile] = useState();
  const [errorImg, setErrorImg] = useState("Click to upload image");
  const [selectedBreakdown, setSelectedBreakdown] = useState([]);

  const updateImgFile = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setImgFile(null);
      return setErrorImg("Upload an image");
    }
    const size = file?.size / 1024 / 1024;
    const type = file?.type?.split("/")[1];
    const validTypes = ["jpeg", "jpg", "png"];
    if (size > 10 || !validTypes.includes(type)) {
      setErrorImg(
        "Invalid. File size must not be more than 10mb\nFile must me either jpg jpeg png"
      );
    } else {
      setErrorImg(null);
      setImgFile(file);
    }
  };

  const formikValues = {
    ...personal_details,
    member_no: memberId,
    other_name: "",
    payment_type_id: "",
    payment: payment,
  };
  const validationSchema = Yup.object({
    first_name: Yup.string().required("Member first name is required"),
    last_name: Yup.string().required("Member last name is required"),
    occupation: Yup.string().required("Member Occupation is required"),
    dob: Yup.string().required("Input date of birth"),
    member_no: Yup.string().required("Input member number"),
    address: Yup.string().required("Input valid address"),
    marital_id: Yup.string().required("Select marital status"),
    next_of_kin_name: Yup.string().required("Input next of kin name"),
    next_of_kin_phone: Yup.string()
      .matches(/^[0-9]{11}$/, "Phone number should be 11 digits")
      .required("Input valid phone number"),
    relationship_status_id: Yup.string().required("Required"),
    gender_id: Yup.string().required("Required"),
    payment_type_id: Yup.string().required("Select payment method"),
    title_id: Yup.string().required("Select title"),
    phone: Yup.string()
      .matches(/^[0-9]{11}$/, "Phone number should be 11 digits")
      .required("Input valid phone number"),
    email: Yup.string()
      .email("Input valid email address")
      .required("Input valid email address"),
  });

  const handleFormSubmit = async (formikValues, { resetForm }) => {
    console.log(formikValues, "formikValues");
    const reqData = {
      ...formikValues,
      branch_id: branchId,
      amount: amount,
      break_down: selectedBreakdown,
      payment_method: formikValues?.payment_type_id,
    };
    const toastId = toast.loading("Submitting");
    let photoUrl =
      "https://www.gravatar.com/avatar/a208380389f40731d3ac10839ec143a2?d=identicon&r=pg&s=100";
    if (imgFile) {
      photoUrl = await uploadImage(imgFile);
    }
    reqData["photo"] = photoUrl;
    console.log(reqData, "reqData>..");
    const payload = await createMember(reqData);
    const { data, error } = payload;
    if (error) {
      return toast.error(error.data?.message || "Failed", { id: toastId });
    }
    if (data.status === false) {
      return toast.error(data.message || "An error occurred", {
        id: toastId,
      });
    }
    toast.success("Member successfully created", { id: toastId });
    setPageName("personal_details");
    resetForm();
    refetchMemberId(branchId);
  };

  const usePage = [
    {
      name: "personal_details",
      component: (
        <PersonalDetails
          updateImgFile={updateImgFile}
          imgFile={imgFile}
          errorImg={errorImg}
        />
      ),
    },
    {
      name: "payment",
      component: (
        <Payment
          amount={amount}
          amountBreakdown={break_down}
          onSelectionChange={setSelectedBreakdown}
        />
      ),
    },
  ];

  return (
    <section className="mx-lg-4 min-vh-100 pb-5">
      {isGeneratingId || isLoadingFee ? (
        <div className="bg-white ">
          <Loading />
        </div>
      ) : (
        <Formik
          initialValues={formikValues}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <CardComp cardClassName={"border-0 ps-3 mb-3"}>
              <h3 className="mb-0 text-black1 fw-bold">Member Registration</h3>
            </CardComp>
            {/*  */}
            <CardComp cardClassName={"border-0 ps-3"}>
              <div className="py-4"></div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col-10 position-relative col-lg-4 d-flex justify-content-center align-items-center">
                  <div
                    className="pointer"
                    // onClick={() => setPageName("personal_details")}
                  >
                    <img
                      src={
                        pageName !== "personal_details"
                          ? "/Icon/tick-circle-success.svg"
                          : "/Members/circle.svg"
                      }
                      alt="selected"
                    />
                    <p
                      style={{ left: "-34px" }}
                      className="text-center position-absolute h60"
                    >
                      Personal details
                    </p>
                  </div>
                  <div
                    style={{ padding: "0.5px 0", backgroundColor: "#525252" }}
                    className="w-100"
                  ></div>
                  <div
                    className="pointer"
                    // onClick={() => setPageName("payment")}
                  >
                    <img
                      src={
                        pageName === "personal_details"
                          ? "/Members/circle-none.svg"
                          : "/Members/circle.svg"
                      }
                      alt="selected"
                    />
                    <p
                      style={{ right: "-18px" }}
                      className="text-center h60 position-absolute"
                    >
                      Payment
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <PageTab PageArray={usePage} page={pageName} />
              </div>
            </CardComp>
            <div className="col-12 py-3"></div>

            {pageName === "personal_details" ? (
              <div className="d-flex justify-content-start">
                <div className="col-5 col-lg-2">
                  <FormikCheckBtn
                    formikFields={[
                      ...Object.keys(personal_details),
                      "member_no",
                    ]}
                    onBtnClick={() => {
                      setPageName("payment");
                      // pageSelectedArray.push("personal_details");
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-start gap-3">
                <div className="">
                  <ButtonComp
                    BorderColor={"#8B313A"}
                    btnText={"Previous"}
                    onClick={() => {
                      setPageName("personal_details");
                    }}
                    btnClassName={
                      "w-100 bg-white1 text-primary1 py-2 px-4 fw-semibold  rounded"
                    }
                    type={"button"}
                  />
                </div>
                <div className="">
                  <ButtonComp
                    btnText={"Proceed"}
                    btnClassName={
                      "w-100 bg-primary1 py-2 px-4 text-white fw-semibold border-0 rounded"
                    }
                    type={"submit"}
                  />
                </div>
              </div>
            )}
          </Form>
        </Formik>
      )}
    </section>
  );
}
