import { useNavigate } from "react-router-dom";
// import AdminLayout from "../../../Layout/AdminLayout";
import AllRoleManagement from "./modules/RoleManagement";
import { PageTitle } from "../modules/PageHeaders";

export default function SettingRoleManagement() {
  const navigate = useNavigate();
  const TableDropDown = [
    {
      name: "Edit",
      action: (data) => {},
      disabled: true,
    },
    {
      name: "Delete",
      action: (data) => {},
      disabled: true,
    },
  ];

  return (
    <section className="px-2 px-lg-4 min-vh-100">
      <div className="py-1"></div>
      <div className="mb-4">
        <PageTitle title={"Role Management"} />
      </div>
      <AllRoleManagement
        TableDropDown={TableDropDown}
        onSubmit={() => navigate("new")}
        emptyTable="No registration fee found"
      />
    </section>
  );
}
