import styled from "styled-components";

export const ButtonComp = ({
  btnText,
  divClassName,
  btnClassName,
  containerClassName,
  onClick,
  type,
  BorderColor,
  BorderWidth,
  ButtonInlineStyle,
  loading,
  ...props
}) => {
  return (
    <ButtonStyle
      className={`w-full ${containerClassName}` }
      BorderColor={BorderColor}
      BorderWidth={BorderColor ? "2px" : BorderWidth}
    >
      <div className={`${divClassName}`}>
        <button
          className={`${btnClassName}`}
          style={{ ...ButtonInlineStyle }}
          onClick={onClick}
          {...props}
          type={type}
        >
          {loading ? "Loading... " : btnText}
        </button>
      </div>
    </ButtonStyle>
  );
};
const ButtonStyle = styled.div`
  button {
    background: transparent;
    border-color: ${(props) => props.BorderColor} !important;
    border-width: ${(props) => props.BorderWidth} !important;
  }
`;
