import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonComp } from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import { ContributionBreakdown } from "./modules/ContributionBreakdown";
// import { ContributionHistoryList } from "./modules/ContributionHistoryList";

export default function Contribution_HistoryDetail() {
  // const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [modalToggle, setModalToggle] = useState(false);
  const [modalPage] = useState(0);

  const useModal = [
    {
      name: "Suspend Modal",
      component: (
        <div>
          <div className="py-3"></div>
          <div className="text-center">
            <h4 className="text-black text-center fw-bold">Delete Staff</h4>
            <p className="mb-5 text-black fw-2">
              Are you sure you want to delete this staff
            </p>
            <div className="row justify-content-around">
              <div className="col-5">
                <ButtonComp
                  btnText={"No"}
                  btnClassName={"border-0 text-white bg-primary1 w-100 py-3"}
                  onClick={() => setModalToggle(false)}
                />
              </div>
              <div className="col-5">
                <ButtonComp
                  btnText={"Yes"}
                  btnClassName={" text-1 btn w-100 py-3"}
                  BorderColor={"#8B313A"}
                />
              </div>
            </div>
          </div>
          <div className="py-3"></div>
        </div>
      ),
    },
  ];

  const TableDropDown = [
    {
      name: "Archive contribution",
      action: (data) => {
        navigate(`${location?.pathname}/1`);
      },
    },
    {
      name: "Contribution breakdown",
      action: (data) => {
        navigate(`/super_admin/contribution_history/1`);
      },
    },
  ];

  // const usePages =[
  //   {
  //     name:'All Staffs',
  //     component:<ContributionBreakdown
  //     TableDropDown={TableDropDown}
  //     modalPage={modalPage}
  //     modalToggle={modalToggle}
  //     useModal={useModal}
  //     setModalToggle={setModalToggle}
  //     title='Contribution History'

  //     />
  //   },
  // //   {
  // //     name:'Single Staff',
  // //     component:<SingleStaff/>
  // //   },
  // ]

  return (
    <ContributionBreakdown
      TableDropDown={TableDropDown}
      modalPage={modalPage}
      modalToggle={modalToggle}
      useModal={useModal}
      setModalToggle={setModalToggle}
      title="Contribution History"
    />
  );
}
