import { useState } from "react";
import {
  ButtonComp,
  FileUploaderComp,
  ImageLoaderUpdate,
  SelectComp2,
  TextInputComp,
  RadioButtonComp,
  CardComp,
} from "../../../../components";
// import FallbackImage from "../../../../components/Common/ImageWrapper";
import { Formik, Form } from "formik";
import { useEditBranchMutation } from "../../../../store/admin/settings/branchSlice";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
// import {
//   useGetCitiesQuery,
//   useGetBanksQuery,
// } from "../../../../store/selectableSlice";
const phoneRegExp = /^([0]{1})[0-9]{10}$/;

export default function EditBranch({ onNext, branchDetails }) {
  const [withLogo, setWithLogo] = useState(false);
  const initialValues = {
    address: branchDetails.address,
    name: branchDetails.name,
    phone: branchDetails.phone,
  };

  // const { data: allCities = [] } = useGetCitiesQuery();
  // const { data: allBanks = [] } = useGetBanksQuery();
  // console.log(allBanks);

  const validationSchema = Yup.object({
    name: Yup.string().required("Branch name cannot be empty"),
    address: Yup.string().required("Address cannot be empty"),
    phone: Yup.string().matches(
      phoneRegExp,
      "Phone number must be eleven didgits"
    ),
  });

  const [submitEditForm, { isLoading }] = useEditBranchMutation();

  const handleSubmit = async (values, { setSubmitting }) => {
    const reqBody = {
      ...values,
      branchId: branchDetails.id,
    };
    setSubmitting(true);
    const toastId = toast.loading("processing");
    const payload = await submitEditForm(reqBody);
    const { data, error } = payload;
    console.log(payload);
    setSubmitting(false);
    console.log(reqBody);
    if (error) {
      return toast.error(error?.data?.message || "an error occured", {
        id: toastId,
      });
    }
    if (data?.status === false) {
      return toast.error(data.message || "An error occured", {
        id: toastId,
      });
    }
    if (data.status === true) {
      return toast.success(data.message || "Success", {
        id: toastId,
      });
    }
  };

  console.log(branchDetails, "branchDetails");

  return (
    <>
      <div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <CardComp
              cardClassName={"pt-4"}
              cardBodyClassName={"py-0 row gx-5 gy-3 justify-content-between"}
            >
              <div className="col-12 col-lg-5">
                {/* <div className="mb-3">
                  <TextInputComp
                    LabelText={"Branch name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Input branch name"}
                    name="name"
                  />
                </div> */}

                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Branch name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Input branch name"}
                    name="name"
                  />
                </div>
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Phone number"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Enter Phone number"}
                    name="phone"
                  />
                </div>
                {/* <div className="mb-3">
                  <SelectComp2
                    LabelClassName={"fw-2"}
                    labelText={"State"}
                    arrayComp={[
                      { value: "", label: "Select Location" },
                      {
                        value: "Lagos",
                        label: "Lagos",
                      },
                    ]}
                  />
                </div> */}
                {/* <div className="mb-3">
                  <SelectComp2
                    LabelClassName={"fw-2"}
                    labelText={"Select bank"}
                    arrayComp={[
                      { value: "", label: "Select Bank" },
                      {
                        value: "GTB",
                        label: "GTB",
                      },
                    ]}
                  />
                </div> */}
              </div>
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Address"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Enter Address"}
                    name="address"
                  />
                </div>
                {/* <div className="mb-4">
                  <FileUploaderComp
                    LabelText={"Profile Image"}
                    labelClassName={"fw-2"}
                    bodyText={
                      <div className="d-flex align-items-center">
                        <ImageLoaderUpdate
                          src={"/FileUploader/upload.svg"}
                          style={{ width: "60%" }}
                          className="me-3"
                        />

                        <div className="fw-2" style={{ color: "#6B6B6B" }}>
                          Click to Upload Image
                        </div>
                      </div>
                    }
                  />
                </div> */}
                {/* <div>
                  <RadioButtonComp
                    fieldName={"with_logo"}
                    checked={withLogo}
                    onChange={() => setWithLogo(!withLogo)}
                    LabelText={
                      <span className="ms-3">use cooperative logo</span>
                    }
                    backgroundColor="#B13E4A"
                    type={"checkbox"}
                  />
                </div> */}
              </div>
            </CardComp>

            <div className="">
              <ButtonComp
                btnText={"Update branch"}
                disabled={isLoading}
                btnClassName={
                  "bg-primary1 py-3 px-4 text-white border-0 rounded"
                }
                type={"submit"}
                loading={isLoading}
              />
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
