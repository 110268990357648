// import AdminLayout from "../../../Layout/AdminLayout";
import CreateFineSetting from "./modules/CreateFineSetting";
import { useGetFineCategoriesQuery } from "../../../store/admin/settings/fineSlice";

const NewFine = () => {
  const { data, error } = useGetFineCategoriesQuery();
  if (error) {
    console.log(error);
  }
  console.log(data);
  return (
    <section className="px-2 px-lg-4 min-vh-100 rounded-4">
      <CreateFineSetting />
    </section>
  );
};

export default NewFine;
