import React, { useState } from "react";
import {
  CardComp,
  ModalCompAdvanceUpdate,
  TableCompData,
} from "../../../components";
import EmptyTable from "../../../components/Common/EmptyTable";
import TablePagination from "../../../components/Table/Pagination";
import {
  useGetContributionCategoriesQuery,
  useDeleteContributionCategoryMutation,
} from "../../../store/admin/contribution";
import { ContributionCategoryHeader } from "../../../utils/TableHeader";
import ConfirmModal from "../modules/ConfirmModal";
import CreateContributionModal from "./modules/create-contribution-modal";
import ViewContribution from "./modules/view-contribution";
import EditContributionModal from "./modules/edit-contribution-modal";
import toast from "react-hot-toast";
// import CreateContributionModal from "./modules/create-contribution-modal";

const ContributionCategory = () => {
  const [modalPage, setModalPage] = useState("");

  const [pageNumber, setPageNumber] = useState(1);

  const [contributionSummary, setContributionSummary] = useState();
  const { data, isFetching, isSearching } =
    useGetContributionCategoriesQuery(pageNumber);

  const tableData = data?.categories;
  const pagination = data?.pagination;

  const TableDropDown = (row) => {
    const dropdown = [
      {
        name: "View contribution",
        action: (data) => {
          setContributionSummary(data);
          setModalPage("View Contribution");
        },
      },
      {
        name: "Edit contribution",
        action: (data) => {
          setContributionSummary(data);
          setModalPage("Edit contribution");
        },
      },
      {
        name: "Archive contribution",
        action: (data) => {
          setContributionSummary(data);
          setModalPage("Delete Modal");
        },
      },
    ];
    return dropdown;
  };

  // eslint-disable-next-line no-unused-vars
  const [handleArchive, { isLoading }] =
    useDeleteContributionCategoryMutation();

  const handleArchiveContribution = async (values) => {
    const updatedValues = {
      id: values.id,
      name: values.name,
      phone: values.phone,
      role_id: values.id,
    };
    const response = await handleArchive(updatedValues);
    if ("error" in response) {
      const { data } = response.error;
      return toast.error(data.message);
    }

    const { message, status } = response.data;
    if (!status) return toast.error(message);
    // handleClose();
    toast.success(message);
  };

  const useModal = [
    {
      name: "Delete Modal",
      component: (
        <ConfirmModal
          title={"Confirm"}
          desc={"Are you sure you want to disable this bank account?"}
          setModalToggle={() => setModalPage("")}
          confimAction={() => handleArchiveContribution(contributionSummary)}
        />
      ),
    },
    {
      name: "Create new category",
      component: <CreateContributionModal />,
    },
    {
      name: "View Contribution",
      component: (
        <ViewContribution
          handleClose={() => setModalPage("")}
          data={contributionSummary}
        />
      ),
    },
    {
      name: "Edit contribution",
      component: (
        <EditContributionModal
          handleClose={() => setModalPage("")}
          previousData={contributionSummary}
        />
      ),
    },
  ];

  // console.log(tableData, "table");

  return (
    <section className="flex flex-col gap-4">
      <div className="h-[82px] w-full bg-white rounded-[10px] flex items-center pl-10">
        <p className="text-2xl font-bold">Contribution Category</p>
      </div>

      <CardComp cardClassName={"border-0 ps-3"}>
        <div className="w-full flex justify-end">
          <button
            onClick={() => setModalPage("Create new category")}
            className="h-[50px] rounded-[10px] font-bold text-white bg-new-primary px-4"
          >
            Create new category
          </button>
        </div>
        <main>
          <div className="mb-5">
            <TableCompData
              marginBottom={"50px"}
              columns={ContributionCategoryHeader(TableDropDown)}
              data={tableData}
              message={
                isFetching || isSearching ? (
                  <div className=" py-4 ">
                    <h3>Loading...</h3>
                  </div>
                ) : (
                  <EmptyTable
                    Message={
                      <h3 className="text-muted pb-4 h40 fw-light">
                        There are currently no active contributions
                      </h3>
                    }
                  />
                )
              }
            />
            {tableData?.length > 0 && (
              <TablePagination
                {...pagination}
                handlePageChange={setPageNumber}
              />
            )}
          </div>
          <div></div>
        </main>
      </CardComp>
      <ModalCompAdvanceUpdate
        pageName={modalPage}
        arrayComp={useModal}
        handleClose={() => setModalPage()}
        centered
        size={"lg"}
      />
    </section>
  );
};

export default ContributionCategory;
