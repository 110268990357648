import { Form, FormControl, InputGroup } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { FiSearch } from "react-icons/fi";
import { ButtonComp } from "./ButtonComp";
import styledComponents from "styled-components";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import { BsCalendar2Event } from "react-icons/bs";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { useField } from "formik";

export const FormikTextAreaComp = ({
  LabelText,
  labelClassName,
  required,
  rows,
  desc,
  fieldName,
}) => {
  const [field, meta] = useField(fieldName);
  return (
    <TextInputCompStyle className="w-100">
      <Form.Group className="w-100" controlId="formBasicEmail">
        {LabelText && (
          <Form.Label className={labelClassName}>
            {LabelText}
            {required && <span className="text6">*</span>}
          </Form.Label>
        )}
        <Form.Control as="textarea" rows={rows} {...field} />
        {desc && <small>{desc}</small>}
      </Form.Group>
      {meta.touched &&
        meta.error && ( // Display validation error if touched and error exists
          <small className="text-danger mt-2">{meta.error.toString()}</small>
        )}
    </TextInputCompStyle>
  );
};

export const FormikDatePicker = ({
  fieldName,
  labelText,
  desc,
  isRequired = false,
  labelClassName,
}) => {
  const [field, meta] = useField(fieldName);
  return (
    <TextInputCompStyle>
      <div className="w-100">
        <Form.Group className="w-100">
          {labelText && (
            <Form.Label className={labelClassName}>
              {labelText}
              {isRequired && (
                <span className="text6" style={{ color: "red" }}>
                  *
                </span>
              )}
            </Form.Label>
          )}
          <InputGroup className="mb-3">
            <Form.Control
              className={`py-2 px-2`}
              {...field} // Bind field properties from Formik
              // {...props}
              type={"date"}
              placeholder="select date of birth"
              isInvalid={meta.touched && meta.error} // Add 'isInvalid' prop to display validation errors
            />
            {/* <span className="input-group-text bg-white" id="basic-addon2">
              <BsCalendar2Event />
            </span> */}
          </InputGroup>
          {desc && <Form.Text className="text-muted">{desc}</Form.Text>}
          {meta.touched &&
            meta.error && ( // Display validation error if touched and error exists
              <small className="text-danger mt-2">
                {meta.error.toString()}
              </small>
            )}
        </Form.Group>
      </div>
    </TextInputCompStyle>
  );
};
export const FormikTimePicker = ({
  fieldName,
  labelText,
  desc,
  isRequired = false,
  labelClassName,
  placeholder = "",
}) => {
  const [field, meta] = useField(fieldName);
  return (
    <TextInputCompStyle>
      <div className="w-100">
        <Form.Group className="w-100">
          {labelText && (
            <Form.Label className={labelClassName}>
              {labelText}
              {isRequired && (
                <span className="text6" style={{ color: "red" }}>
                  *
                </span>
              )}
            </Form.Label>
          )}
          <InputGroup className="mb-3">
            <Form.Control
              className={`py-2 px-2`}
              {...field}
              type={"time"}
              placeholder={placeholder}
              isInvalid={meta.touched && meta.error}
            />
          </InputGroup>
          {desc && <Form.Text className="text-muted">{desc}</Form.Text>}
          {meta.touched &&
            meta.error && ( // Display validation error if touched and error exists
              <small className="text-danger mt-2">
                {meta.error.toString()}
              </small>
            )}
        </Form.Group>
      </div>
    </TextInputCompStyle>
  );
};

export const TextInputComp = ({
  LabelText,
  labelText,
  LabelClassName,
  InputClassName,
  required,
  desc,
  errors,
  borderColor,
  borderWidth,
  descClassName,
  placeholder,
  suffixIcon,
  prefixIcon,
  ...props
}) => {
  const [password, setPassword] = useState(true);

  // Use Formik's `useField` hook to access the field properties
  const [field, meta] = useField(props.name);

  return (
    <TextInputCompStyle
      borderColor={borderColor}
      borderWidth={borderWidth}
      PrefixIcon={prefixIcon || (props?.type === "password" && true)}
    >
      <div className="w-100">
        {(LabelText || labelText) && (
          <Form.Label className={LabelClassName}>
            {LabelText || labelText}
            {required && (
              <span className="text6" style={{ color: "red" }}>
                *
              </span>
            )}
          </Form.Label>
        )}
        <InputGroup className="mb-1">
          {suffixIcon && (
            <InputGroup.Text id="basic-addon1">{suffixIcon}</InputGroup.Text>
          )}

          <Form.Control
            className={`py-2 ${InputClassName} z-auto`}
            placeholder={placeholder}
            {...field} // Bind field properties from Formik
            {...props}
            type={
              props?.type === "password" && password ? "password" : props.type
            }
            isInvalid={meta.touched && meta.error} // Add 'isInvalid' prop to display validation errors
          />
          {desc && (
            <Form.Text className={`h60 ${descClassName}`}>{desc}</Form.Text>
          )}

          {props?.type === "password" && (
            <InputGroup.Text id="basic-addon1">
              {props?.type === "password" && password ? (
                <AiOutlineEyeInvisible
                  onClick={() => setPassword(false)}
                  size={20}
                />
              ) : (
                <AiOutlineEye onClick={() => setPassword(true)} size={20} /> ||
                prefixIcon
              )}
            </InputGroup.Text>
          )}
        </InputGroup>
        {meta?.touched &&
          meta?.error && ( // Display validation error if touched and error exists
            <small className="text-danger mt-2">{meta?.error.toString()}</small>
          )}
      </div>
    </TextInputCompStyle>
  );
};

export const DatePickerInputComp = ({
  LabelText,
  labelText,
  LabelClassName,
  InputClassName,
  required,
  desc,
  errors,
  borderColor,
  borderWidth,
  value,
  placeholder,
  ...props
}) => {
  return (
    <TextInputCompStyle borderColor={borderColor} borderWidth={borderWidth}>
      <div className="w-100">
        <Form.Group className="w-100">
          {(LabelText || labelText) && (
            <Form.Label className={LabelClassName}>
              {LabelText || labelText}
              {required && (
                <span className="text6" style={{ color: "red" }}>
                  *
                </span>
              )}
            </Form.Label>
          )}
          <div className="input-group mb-3">
            <span className="form-control">
              <DatePicker
                placeholderText={placeholder}
                className={`w-100 border-0  ${InputClassName}`}
                selected={value}
                onChange={(date) => {}}
              />
            </span>
            <span className="input-group-text bg-white" id="basic-addon2">
              <BsCalendar2Event />
            </span>
          </div>
          {desc && <Form.Text className="text-muted">{desc}</Form.Text>}
          {errors?.map((error, i) => (
            <small className="text-danger mt-2" key={i}>
              {error}
            </small>
          ))}
        </Form.Group>
      </div>
    </TextInputCompStyle>
  );
};

export const TextAreaComp = ({
  LabelText,
  labelClassName,
  required,
  rows,
  desc,
  errors,
  ...props
}) => {
  return (
    <TextInputCompStyle className="w-100">
      <Form.Group className="w-100" controlId="formBasicEmail">
        {LabelText && (
          <Form.Label className={labelClassName}>
            {LabelText}
            {required && <span className="text6">*</span>}
          </Form.Label>
        )}
        <Form.Control as="textarea" rows={rows} {...props} />
        {desc && <small>{desc}</small>}
      </Form.Group>
      {errors?.map((error, i) => (
        <small className="text-danger mt-2" key={i}>
          {error}
        </small>
      ))}
    </TextInputCompStyle>
  );
};

export const RadioButtonComp = ({
  LabelText,
  labelClassName,
  InputClassName,
  required,
  backgroundColor,
  shape,
  type,
  fieldName,
  ...props
}) => {
  const [field] = useField(fieldName);
  // const handleChange = (event) => {
  //   const isChecked = event.target.checked;
  //   const value = isChecked ? 1 : 0;
  //   helpers.setValue(value);
  // };
  return (
    <RadioButtonCompStyle
      className="w-100"
      backgroundColor={backgroundColor}
      shape={shape}
    >
      <div className=""></div>
      <Form.Check
        className="d-flex align-items-center"
        type={type || "radio"}
        label={LabelText}
        {...props}
        {...field}
        id={`default-${type}`}
      />
      <div>{field.value}</div>
    </RadioButtonCompStyle>
  );
};

export const SearchInput = ({
  LabelText,
  labelClassName,
  InputClassName,
  placeholder,
  ...props
}) => {
  return (
    <div className="w-100  bg8">
      <InputGroup className="w-100">
        <FormControl
          placeholder={placeholder}
          aria-label="Username"
          aria-describedby="basic-addon1"
          className="bg8"
        />
        <InputGroup.Text id="basic-addon1" className="bg9">
          <ButtonComp
            btnClassName={"bg10  rounded"}
            btnText={<FiSearch size={30} color="white" />}
          />
        </InputGroup.Text>
      </InputGroup>
    </div>
  );
};

export const SearchInputRefine = ({
  LabelText,
  labelClassName,
  InputClassName,
  sideB,
  placeholder,
  ...props
}) => {
  return (
    <SearchInputRefineStyle className="w-100  ">
      {LabelText && <Form.Label className={{}}>{LabelText}</Form.Label>}
      <InputGroup className="">
        <FormControl
          placeholder={placeholder}
          //  aria-label="Username"
          aria-describedby="basic-addon1"
          className={InputClassName}
        />
        <InputGroup.Text id="basic-addon1" className={labelClassName}>
          {sideB}
        </InputGroup.Text>
      </InputGroup>
    </SearchInputRefineStyle>
  );
};
export const SearchInputRefine2 = ({
  LabelText,
  labelClassName,
  InputClassName,
  sideB,
  placeholder,
  ...props
}) => {
  return (
    <SearchInputRefineStyle className="w-100  ">
      {LabelText && <Form.Label className={{}}>{LabelText}</Form.Label>}
      <InputGroup.Text id="basic-addon1" className={labelClassName}>
        {sideB}
      </InputGroup.Text>
      <InputGroup className="">
        <FormControl
          placeholder={placeholder}
          //  aria-label="Username"
          aria-describedby="basic-addon1"
          className={InputClassName}
        />
      </InputGroup>
    </SearchInputRefineStyle>
  );
};

export const PhoneInputComp = ({
  LabelText,
  labelText,
  labelClassName,
  LabelClassName,
  InputClassName,
  value,
  onChange,
  errors,
  ...props
}) => {
  return (
    <div>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        {(LabelText || labelText) && (
          <Form.Label className={LabelClassName}>
            {LabelText || labelText}
          </Form.Label>
        )}
        <div>
          <PhoneInput
            className="border border-1 py-2 rounded px-3 shadow-sm w-100"
            placeholder="Enter phone number"
            defaultCountry="NG"
            value={value || props?.defaultValue}
            //  defaultValue={props?.defaultValue}
            onChange={(e) => onChange(e)}
            // {...props}
          />
        </div>
      </Form.Group>
      {errors?.map((error, i) => (
        <small className="text-danger mt-2" key={i}>
          {error}
        </small>
      ))}
    </div>
  );
};

export const CheckComp = ({ LabelText }) => {
  return (
    <CheckCompStyle>
      <Form>
        <Form.Check type="switch" id="custom-switch" label={LabelText} />
      </Form>
    </CheckCompStyle>
  );
};

export const MoneyInputComp = ({
  LabelText,
  LabelClassName,
  InputClassName,
  placeholder,
  desc,
  descClassName,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);

  const handleChange = (value) => {
    helpers.setValue(value);
  };

  return (
    <TextInputCompStyle>
      <Form.Group className={`border-0 ${LabelClassName}`}>
        {LabelText && (
          <Form.Label className={LabelClassName}>{LabelText}</Form.Label>
        )}
        <div>
          <CurrencyInput
            className={`w-100 form-control py-2 ${InputClassName}`}
            placeholder={placeholder}
            // defaultValue={000}
            prefix="₦"
            decimalsLimit={2}
            // defaultValue={0}
            value={field.value}
            onValueChange={handleChange}
            {...props}
          />
        </div>
        {desc && (
          <Form.Text className={`h60 ${descClassName}`}>{desc}</Form.Text>
        )}
        {meta.touched && meta.error && (
          <Form.Text className={`h60 ${descClassName}`}>{meta.error}</Form.Text>
        )}
      </Form.Group>
    </TextInputCompStyle>
  );
};

export const CheckBoxComp = ({
  labelText,
  labelClassName,
  inputClassName,
  required,
  backgroundColor,
  shape,
  ...props
}) => {
  return (
    <RadioButtonCompStyle
      className=""
      backgroundColor={backgroundColor}
      shape={shape}
    >
      <div className=""></div>
      <Form.Check
        type={"checkbox"}
        label={labelText}
        {...props}
        // id={`disabled-default-${type}`}
      />
    </RadioButtonCompStyle>
  );
};

const SearchInputRefineStyle = styledComponents.div`
.input-group-text {
 
  // background-color: transparent !important;
  
}
`;
const RadioButtonCompStyle = styledComponents.div`
.form-check-input,.form-check-input:checked{
  border-color:${(prop) =>
    prop.backgroundColor ? prop.backgroundColor : "black "}  !important;
}
.form-check-input{
  margin-top: 0 !important;
  width: 22px !important;
    height: 22px !important;
    border:1px solid ${(prop) =>
      prop.backgroundColor ? prop.backgroundColor : "black "} !important;
}
.form-check-input:checked{
  background-color:${(prop) =>
    prop.backgroundColor ? prop.backgroundColor : "black "} !important;
}
.form-check-input[type=radio],.form-check-input[type=checkbox] {
  border-radius: ${(prop) => (prop.shape ? "50%" : "0%")};
}
label{
  margin-left:5px;
}

`;

export const TextInputCompStyle = styledComponents.div`
input,.input-group,.react-datepicker__input-container,textarea{
  border-width: ${(props) =>
    props?.borderWidth ? props?.borderWidth : "1px"} !important;
    border-color: ${(props) =>
      props?.borderColor ? props?.borderColor : "#A1A0A0"} !important;
    
}
input, select, textarea {
  background-color: ${(props) =>
    props?.backgroundColor ? props?.backgroundColor : "#FAFAFA"} !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-control{
  border-width: ${(props) =>
    props?.borderWidth ? props?.borderWidth : "1px"} !important;
  border-color: ${(props) =>
    props?.borderColor ? props?.borderColor : "#A1A0A0"} !important;
    border-right: ${(props) => (props?.PrefixIcon ? "none" : "")}  !important;
}
.input-group-text{
  background-color:transparent !important;
  border-width: 1px !important;
    border-color: #A1A0A0 !important;
}
`;
const CheckCompStyle = styledComponents.div`
.form-check-input:checked {
  background-color: #20B16A !important;
  border-color: #20B16A !important;
}
`;
