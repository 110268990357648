import React from "react";
import { useState } from "react";
// import { BsArrowLeft } from "react-icons/bs";
import {
  ButtonComp,
  CardComp,
  DropDownComp,
  // SelectComp,
  SelectComp2,
  TableCompData,
} from "../../../../components";
import FallbackImage from "../../../../components/Common/ImageWrapper";
import FilterFind from "../../../../components/FilterFind";
import {
  MemberTransactionHistoryData,
  MemberTransactionHistoryHeader,
} from "../../../../utils/TableHeader";
import { useNavigate } from "react-router-dom";
export default function TransactionHistory({ setPageName }) {
  const navigate = useNavigate();
  const [transactionType, setTransactionType] = useState("Contributions");
  return (
    <section className="mx-3 mx-lg-5 min-vh-100">
      <FilterFind
        title={"Transaction History"}
        back
        backBtn={() => navigate(-1)}
        rightSide={false}
      />
      <CardComp cardClassName={"border-0"}>
        <div>
          <div className="row justify-content-between g-3 mb-3">
            <div className="py-1"></div>
            <div className="col-lg-5">
              <div className="d-flex align-items-end">
                <SelectComp2
                  setSelectedOption={(e) => setTransactionType(e?.value)}
                  LabelText={"Transaction type"}
                  LabelClassName={"fw-2"}
                  onChange={(e) => setTransactionType(e.target.value)}
                  // selectText={"Select Transaction Type"}

                  selectClassName={"w-100 py-2"}
                  arrayComp={[
                    {
                      label: "Contributions",
                      value: "Contributions",
                    },
                    {
                      label: "Loans",
                      value: "Loans",
                    },
                    {
                      label: "Personal",
                      value: "Personal",
                    },
                    {
                      label: "savings",
                      value: "savings",
                    },
                  ]}
                />
              </div>
            </div>
            {transactionType === "Loans" && (
              <div className="col-lg-3 d-flex justify-content-end align-items-end">
                <div className="d-flex align-items-end w-100">
                  <div className="col-10">
                    <ButtonComp
                      btnText={"View credit score"}
                      btnClassName={
                        "bg-primary1 rounded text-white py-2 border-0 w-100"
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/*  */}
          <div className="d-flex justify-content-end align-items-center mb-5 gap-2">
            <ButtonComp
              btnText={"All transactions"}
              btnClassName="bg-secondary3   border-0 px-3  py-1 text-secondary2 rounded-pill"
              style={{ background: "rgba(49, 119, 140, 0.1);" }}
            />
            <span>
              <DropDownComp
                dropLabelClassName="border-0"
                DropDownText={
                  <FallbackImage
                    width={50}
                    height={50}
                    src="/Members/filter.png"
                    alt=""
                  />
                }
                arrayComp={[
                  {
                    name: (
                      <span>
                        <img src="/Members/filter-simple.svg" alt="" /> Filter
                      </span>
                    ),
                  },
                  {
                    name: "All transactions",
                  },
                  {
                    name: "Successful transactions",
                  },
                  {
                    name: "Failed transactions",
                  },
                ]}
                input="name"
              />
            </span>
          </div>
          {/*  */}
          <div>
            <TableCompData
              columns={MemberTransactionHistoryHeader()}
              data={MemberTransactionHistoryData}
              marginBottom={"70px"}
            />
          </div>
        </div>
      </CardComp>
    </section>
  );
}
