import React, { useState } from "react";
import FallbackImage from "../../../../components/Common/ImageWrapper";
import { formatMoney } from "../../../../utils";

export default function ActivitiesRow({ item, itemPosition, arrayLength }) {
  // const [{ date, time }] = useState(() => {
  //   const dateObj = new Date(item?.createdAt ?? item?.created_at);
  //   console.log(item,'ActivitiesRow')
  //   return {
  //     // date: dateObj?.toISOString().split("T")[0],
  //     // time: dateObj?.toTimeString().split(" ")[0].substring(0, 5),
  //   };
  // });

  // console.log(arrayLength=== 1+itemPosition)
  return (
    <div
      className={`d-flex justify-content-between align-items-center   pb-2 mb-2 flex-wrap ${
        arrayLength !== itemPosition + 1 && "border-bottom"
      }`}
    >
      <div
        // style={{ width: "168px" }}
        className="d-flex flex-1 align-items-center col-12 col-md-auto  h60"
      >
        <div className="me-2 ">
          {" "}
          <FallbackImage
            src={item?.memberImage || "/Staff/avatar.svg"}
            width="32px"
            height="32px"
            className="rounded-circle"
          />
        </div>
        {item?.member}
      </div>
      {item?.date&&<div className="h60 flex-1 ">{item?.date}</div>}
      {item?.time&&<div className="h60 mb-3 flex-1  mb-md-0 text-center">{item?.time||"-"}</div>}
      {item?.status&&<div className="flex-1 ">
        <div className="text-sm  rounded-sm bg-gray-300 inline-block p-1">{item?.status||'-'}</div></div>}
      {item?.does && (
        <div
          style={{ width: "90px" }}
          className="text-center flex-1 col-12 col-md-auto h60"
        >
          {item?.does}
        </div>
      )}
      {!item?.does && (
        <div className=" flex-1 col-12 text-end col-md-auto">
          <div className={`${item?.amountClassName || "h5"} mb-0`}>
            {formatMoney(item?.amount)}
          </div>
          {item?.payment && <div className="h60"> {item?.payment}</div>}
        </div>
      )}
    </div>
  );
}
