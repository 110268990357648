import { API } from "../api";
const BASE_URL = "/cooperatives/calendar_events";

const eventsExtendedApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (page = 1) => `${BASE_URL}?guard=staff&page=${page}`,
      transformResponse: (responseData) => {
        const { event_calendars = [], pagination = {} } = responseData.data;
        return {
          event_calendars,
          pagination,
        };
      },
      providesTags: (result, error, arg) => {
        if (!result?.event_calendars)
          return [{ type: "AdminEvent", id: "LIST" }];
        return [
          { type: "AdminEvent", id: "LIST" },
          result?.event_calendars?.map(({ id }) => ({
            type: "AdminEvent",
            id: id,
          })),
        ];
      },
    }),
    getSingleEvent: builder.query({
      query: (id) => `${BASE_URL}/${id}?guard=staff`,
      providesTags: (result, err, arg) => [{ type: "AdminEvent", id: arg }],
      transformResponse: (responseData) => responseData.data,
    }),
    addEvent: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/add?guard=staff`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "AdminEvent", id: "LIST" }],
    }),
    editEvent: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/${body.id}/edit?guard=staff`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminEvent", id: arg },
      ],
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `${BASE_URL}/${id}/delete?guard=staff`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "AdminEvent", id: arg },
      ],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useAddEventMutation,
  useGetSingleEventQuery,
  useEditEventMutation,
  useDeleteEventMutation,
} = eventsExtendedApi;
