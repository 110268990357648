import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { DropDownComp, ImageLoaderUpdate } from "../../../components";
import CopyComp from "../../../components/Common/CopyComp";
import { ReactComponent as Edit } from "../../../assets/svgs/edit-icon.svg";
import { ReactComponent as GreenNaira } from "../../../assets/svgs/green-naira.svg";
import { ReactComponent as Cash } from "../../../assets/svgs/cash.svg";
import { ReactComponent as RedNaira } from "../../../assets/svgs/red-naira.svg";
import { formatMoney } from "../../../utils";

const MemberAndStaffProfile = ({
  memberId,
  fullname,
  photo,
  // officeStreet,
  // officeLandmark,
  // officeState,
  // homeStreet,
  // homeLandmark,
  // homeState,
  phone,
  email,
  memberDetails,
  // title = "Profile details",
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(memberDetails,'memberDetails')
  // { icon, amount, progressBg, iconBg, title, bg }
  const LoansData = [
    {
      icon: <Cash fill="#318C64" />,
      amount: 400000,
      progressBg: "bg-[#8B313A]",
      iconBg: "bg-[#A8E1C8]",
      title: "Total Loan Taken",
      bg: "bg-[rgba(139,49,58,0.1)]",
    },
    {
      icon: <Cash fill="#383838" />,
      amount: 400000,
      progressBg: "bg-[#318C64]",
      iconBg: "bg-[#A8E1C8]",
      title: "Current Loan Taken",
      bg: "bg-[rgba(49,140,100,0.1)]",
    },
    {
      icon: <Cash fill="#FF4343" />,
      amount: 400000,
      progressBg: "bg-[#FF4343]",
      iconBg: "bg-[rgba(255,67,67,0.1)]",
      title: "Total Loan Debt",
      bg: "bg-[rgba(255,67,67,0.1)]",
    },
  ];

  // console.log(memberDetails, "memberDetails");
  return (
    <section className="w-full flex flex-col gap-4">
      {/* header */}
      <div className="w-full h-[82px] flex gap-2 items-center pl-10 bg-white rounded-[10px]">
        <BsArrowLeft
          size={30}
          onClick={() => navigate(-1)}
          className="me-3 pointer"
        />
        <h4 className=" text-2xl font-bold text-[#45464E]">Profile details</h4>
      </div>
      {/* header end */}
      <div className="w-full flex flex-col lg:flex-row gap-6">
        <div className="w-full lg:w-[40%] bg-white rounded-3xl px-4">
          <div className="w-full relative border-b pb-4">
            <div className="w-full h-[180px] bg-[#E0E0E0] rounded-b-[50px] relative"></div>
            <div className="w-full flex flex-col items-center -mt-[60px]">
              <div className="h-[150px] w-[150px] rounded-full  relative ">
                <ImageLoaderUpdate
                  src={photo}
                  width="150"
                  height="150"
                  imgClass="rounded-full"
                />
                <div className="absolute -right-4 h-10 w-10 rounded-full bg-white top-[50%] grid place-content-center cursor-pointer">
                  <Edit />
                </div>
              </div>
              <p className="text-[#1E1E1E] font-semibold">{fullname} </p>
              <p className="text-[#858585] text-sm">Member ID: {memberId}</p>
            </div>
          </div>
          <div className="py-4 flex flex-col gap-4 border-b">
            <p className="text-[#1E1E1E] font-semibold">Contact Information</p>
            <div>
              <p className="text-[#858585] text-sm">Email address</p>

              <div className="flex justify-between w-full">
                <p className="text-[#1E1E1E] font-semibold">{email}</p>
                <CopyComp text={email} iconClassName="ms-2" />
              </div>
            </div>
            <div>
              <p className="text-[#858585] text-sm">Phone number</p>
              <div className="flex justify-between w-full">
                <p className="text-[#1E1E1E] font-semibold">{phone}</p>
                <CopyComp text={phone} iconClassName="ms-2" />
              </div>
            </div>
          </div>
          <div className="w-full py-4 flex flex-col gap-4">
            <h4 className=" text-base font-semibold text-[#1E1E1E]">
              Address Information
            </h4>
            <div className="">
              <p className="text-[#1E1E1E] font-semibold">Full address</p>
              <p className="text-[#858585] text-sm">
                {memberDetails?.address || "N/A"}
              </p>
            </div>
            <div className="flex justify-between flex-wrap">
              <div className="">
                <p className="text-[#1E1E1E] font-semibold">Country</p>
                <p className="text-[#858585] text-sm">
                  {memberDetails?.country?.name}
                </p>
              </div>
              <div className="">
                <p className="text-[#1E1E1E] font-semibold">State</p>
                <p className="text-[#858585] text-sm">
                  {memberDetails?.city?.name}
                </p>
              </div>
              <div className="">
                <p className="text-[#1E1E1E] font-semibold">Landmark</p>
                <p className="text-[#858585] text-sm">
                  {memberDetails?.city?.landmark || "N/A"}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="py-4 flex w-full">
            <button className="w-[103px] h-9 rounded text-white bg-[#8B313A]">
              Edit Profile
            </button>
          </div> */}
        </div>
        <div className="w-full rounded-[20px] p-4 lg:p-10 flex flex-col gap-4 ">
          <div className="flex justify-between ">
            <p className="text-text-primary font-bold text-2xl">
              Financial Record
            </p>

            <div className="relative ">
              <DropDownComp
                DropDownText={"Overall"}
                show
                dropLabelClassName="border text-muted p-1 h50"
                borderColor={"#979699"}

              />
            </div>
          </div>

          <div className="p-4 w-full bg-white max-w-[320px] flex-col flex justify-center items-center rounded">
            <div className="flex justify-between w-full items-center">
              <p>Contributions</p>
              <Link
                to={`${location?.pathname}/history`}
                className="text-[#8B313A] text-sm"
              >
                See history
              </Link>
            </div>
            <Card
              icon={<GreenNaira />}
              iconBg={"bg-[#eaf3ef]"}
              progressBg={"bg-[#318C64]"}
              amount={memberDetails?.total_contribution}
              title={"Total Contributions"}
              bg="bg-[rgba(49,140,100,0.1)]"
            />
          </div>

          <div className="w-full p-4 flex flex-col gap-4  bg-white rounded-[20px]">
            <div className="flex justify-between w-full items-center">
              <p>Loans</p>
              <Link
                to={`${location?.pathname}/history`}
                className="text-[#8B313A] text-sm"
              >
                See history
              </Link>
            </div>

            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
              {memberDetails?.loan_breakdowns?.map((item, i) => (
                <Card
                  key={i}
                  icon={item.icon}
                  iconBg={item.iconBg}
                  progressBg={item.progressBg}
                  amount={item.amount}
                  title={item.title}
                  bg={item.bg}
                />
              ))}
            </div>
          </div>
          <div className="p-4 w-full bg-white max-w-[320px] flex-col flex justify-center items-center rounded">
            <div className="flex justify-between w-full items-center">
              <p>
              Overdue Repayment</p>
              <Link
                to={`${location?.pathname}/history`}
                className="text-[#8B313A] text-sm"
              >
                {/* See history */}
              </Link>
            </div>
            <Card
              icon={<RedNaira />}
              iconBg={"bg-[rgba(255,67,67,0.1)]"}
              progressBg={"bg-[#FF4343]"}
              amount={memberDetails?.overdue_repayment}
              title={"Total Loan Debt"}
              bg="bg-[rgba(255,67,67,0.1)]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MemberAndStaffProfile;

const Card = ({ icon, amount, progressBg, iconBg, title, bg }) => {
  return (
    <div className="w-full h-[120px] p-2 flex gap-3 items-center rounded-[10px] mt-2 shadow-md">
      <div
        className={`h-[60px] w-[60px] rounded-full ${iconBg} grid place-content-center`}
      >
        {icon}
      </div>
      <div className="flex-1">
        <p className="text-black text-[24px]">{formatMoney(amount || "0")}</p>
        <p className="text-text-secondary text-sm ">{title}</p>
        <div className={`w-full h-[6px] rounded mt-1 ${bg}`}>
          <div className={`h-full w-[40%] rounded ${progressBg}`}></div>
        </div>
      </div>
    </div>
  );
};
