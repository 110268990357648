import { useNavigate, useParams } from "react-router-dom";
import EventForm from "./modules/EventForm";
import { useGetSingleEventQuery } from "../../../store/admin/eventSlice";
import useEventActions from "./useEventActions";
import { PageTitleWithNav } from "../modules/PageHeaders";

export default function EditEventCalendar() {
  const { updateEvent } = useEventActions();
  const navigate = useNavigate();
  const { id } = useParams();
  const { error, data = {}, isLoading } = useGetSingleEventQuery(id);

  const handleFormSubmit = async (formikValues) => {
    await updateEvent(formikValues);
    navigate(-1);
  };

  if (error) {
    console.log(error);
  }

  return (
    <>
      {isLoading ? (
        <div className="py-4">
          <h2>Loading...</h2>
        </div>
      ) : (
        <section className="px-lg-3 min-vh-100 pb-5 mb-5">
          <PageTitleWithNav
            title={"Edit"}
            highlightText={data?.name}
            action={() => navigate(-1)}
          />
          <EventForm initialFormValues={data} onNext={handleFormSubmit} />
        </section>
      )}
    </>
  );
}
