import React, { useState } from "react";
import {
  ButtonComp,
  CardComp,
  TextInputComp,
  FormikSelectComp,
  FormikDatePicker,
} from "../../../components";
// import AdminLayout from "../../../Layout/AdminLayout";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useGetRolesQuery,
  useGetCountriesQuery,
} from "../../../store/selectableSlice";
import {
  useGetBranchesQuery,
  selectBranchesData,
} from "../../../store/admin/settings/branchSlice";
import { useCreateStaffMutation } from "../../../store/admin/staff";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useSelector } from "react-redux";

export const StaffRegistrationHome = () => {
  const [imgFile, setImgFile] = useState();
  const [errorImg, setErrorImg] = useState("Click to upload image");

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dob: "",
    role_id: "",
    address: "",
    country_id: " ",
  };

  const [createStaff, { isLoading: isCreating }] = useCreateStaffMutation();

  const updateImgFile = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setImgFile(null);
      return setErrorImg("Upload an image");
    }
    const size = file?.size / 1024 / 1024;
    const type = file?.type?.split("/")[1];
    const validTypes = ["jpeg", "jpg", "png"];
    if (size > 10 || !validTypes.includes(type)) {
      setErrorImg(
        "Invalid. File size must not be more than 10mb\nFile must me either jpg jpeg png"
      );
    } else {
      setErrorImg(null);
      setImgFile(file);
    }
  };

  const { data: roles = [] } = useGetRolesQuery();

  const { isLoading: isLoadingBranches } = useGetBranchesQuery();
  const branches = useSelector(selectBranchesData);

  const { data: countries = [] } = useGetCountriesQuery();

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Enter valid email").required("Required"),
    phone: Yup.string()
      .matches(/^[0-9]{11}$/, "Input 11 phone number")
      .required("Required"),
    dob: Yup.string().required("Required"),
    role_id: Yup.string().required("Required"),
    address: Yup.string().required("Address is required"),
    country_id: Yup.string().required("Required"),
  });

  const handleFormSubmit = async (
    formikValues,
    { setSubmitting, resetForm }
  ) => {
    const updatedFormikValues = {
      ...formikValues,
      branch_id: parseInt(formikValues.branch_id),
    };
    if (errorImg) {
      setSubmitting(false);
      return;
    }
    const reqData = new FormData();

    for (const key in updatedFormikValues) {
      reqData.append([key], updatedFormikValues[key]);
    }
    // reqData.append("branch_id", userData?.profile?.active_branch_id);
    const toastId = toast.loading("Submitting");

    const photoData = new FormData();
    photoData.append("file", imgFile);
    photoData.append("upload_preset", "svmprhvf");
    const photoUrl = await axios
      .post("https://api.cloudinary.com/v1_1/afrilocales/upload", photoData)
      .then((response) => {
        return response?.data?.secure_url;
      })
      .catch(() => {
        return "https://blog.namely.com/hs-fs/hubfs/biweekly-1.png?width=2400&name=biweekly-1.png";
      });
    reqData.append("photo", photoUrl);
    try {
      const payload = await createStaff(reqData).unwrap();
      if (payload.status === false) {
        return toast.error(payload?.message || "An error occurred", {
          id: toastId,
        });
      }
      toast.success("Member successfully created", { id: toastId });
      resetForm();
    } catch (error) {
      toast.error(error?.data?.message || "Failed", { id: toastId });
    } finally {
      return setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      <Form>
        <section className=" px-3 px-lg:5 min-vh-100">
          <CardComp cardClassName={"border-0 px-lg-4 mb-3 py-2"}>
            <h3 className="mb-0 text-black1 fw-bold">Registration</h3>
          </CardComp>
          <CardComp cardClassName={"border-0 px-lg-4"}>
            <div className="py-4"></div>
            <main className="col-11">
              <div className="row gx-5 gy-3 justify-content-between">
                <div className="col-12 col-lg-5">
                  <TextInputComp
                    LabelText={"First name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    name="first_name"
                    placeholder="Please enter your first name"
                    type="text"
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <TextInputComp
                    LabelText={"Last name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    name="last_name"
                    placeholder="Please enter your last name"
                    type="text"
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <TextInputComp
                    LabelText={"Email"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    name="email"
                    placeholder="Please enter your email address"
                    type="email"
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <TextInputComp
                    LabelText={"Phone number"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    type="number"
                    name="phone"
                    placeholder="Please enter your phone number"
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <FormikDatePicker
                    fieldName={"dob"}
                    labelText={"Date of birth"}
                    labelClassName={"fw-2"}
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <FormikSelectComp
                    labelClassName={"fw-2"}
                    fieldName={"role_id"}
                    labelText={"Role"}
                    options={roles.map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))}
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <FormikSelectComp
                    labelClassName={"fw-2"}
                    fieldName={"branch_id"}
                    labelText={"Branch"}
                    options={branches.map((branch) => ({
                      value: branch.id,
                      label: branch.name,
                    }))}
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <TextInputComp
                    LabelText={"Address"}
                    placeholder={"Aare avenue, Bodija, Ibadan, Oyo state"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    type="text"
                    name="address"
                  />
                </div>
                <div className="col-12 col-lg-5">
                  <FormikSelectComp
                    labelClassName={"fw-2"}
                    fieldName={"country_id"}
                    labelText={"Country"}
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                  />
                </div>
                <div className="col-12 col-lg-5 ">
                  <label
                    className="fw-2 "
                    htmlFor="imgFile"
                    style={{ display: "block", cursor: "pointer" }}
                  >
                    Profile image
                    <img
                      src="/FileUploader/upload.svg"
                      className="d-block mt-2"
                      width={"80"}
                      height={"80"}
                      alt="upload"
                    />
                    <input
                      onChange={updateImgFile}
                      name="file"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      style={{ display: "none" }}
                      id="imgFile"
                    />
                  </label>{" "}
                  {imgFile && <small className="mt-2">{imgFile?.name}</small>}
                  {errorImg && (
                    <small className="text-danger mt-2">{errorImg}</small>
                  )}
                </div>
              </div>
              <div className="col-12 py-3"></div>
            </main>
          </CardComp>
          <div>
            <div className="col-12 py-3"></div>
            <div className="d-flex">
              <div className="">
                <ButtonComp
                  type={"submit"}
                  loading={isCreating}
                  disabled={isLoadingBranches}
                  btnText={"Submit"}
                  btnClassName={
                    " bg-primary1 py-2 px-5 text-white border-0 rounded"
                  }
                />
              </div>
            </div>
          </div>
          <div className="py-4"></div>
        </section>
      </Form>
    </Formik>
  );
};
