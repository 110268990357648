import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetAllBankInfoQuery } from "../../../../store/admin/settings/societyBankSlice";
import { useUpdateContributionCategoryMutation } from "../../../../store/admin/contribution";
import toast from "react-hot-toast";
import { Input } from "../../../../components/Ui/new-input";
import CustomSelect from "../../../../components/custom-select/custom-select";
import { customStyles } from "../../../../components/custom-select/custom-styles";
import { useGetBranchesQuery } from "../../../../store/admin/settings/branchSlice";

export default function EditContributionModal({ previousData, handleClose }) {
  // const navigate = useNavigate();
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedWithdrawableStatus, setSelectedWithdrawableStatus] =
    useState(null);
  const { data: branches = [], isLoading: loadingBranches } =
    useGetBranchesQuery();

  const formik = useFormik({
    initialValues: {
      is_withdrawable: "",
      company_bank_id: "",
      threshold: 0,
      end_date: new Date(),
      // start_date: new Date(),
      amount: "",
      branches: [],
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      threshold: Yup.number("Required"),
      amount: Yup.number().required("Required"),
      company_bank_id: Yup.string().required("Required"),
      end_date: Yup.string().required("Required"),
      // start_date: Yup.string().required("Required"),
      branches: Yup.array().of(
        Yup.object().shape({
          branch_id: Yup.number().required("Required"),
        })
      ),
    }),
  });

  useEffect(() => {
    formik.setValues({
      id: previousData?.id,
      name: previousData?.name,
      threshold: previousData?.target_amount,
      amount: previousData?.amount_remaining,
      company_bank_id: previousData?.company_bank_id,
      end_date: previousData?.end_date,
      // start_date: previousData?.start_date,
      is_withdrawable: previousData?.is_withdrawable,
      // branches: [previousData.branch_id],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousData]);

  const [handleUpdateContributionCategory, { isLoading }] =
    useUpdateContributionCategoryMutation();
  const { data } = useGetAllBankInfoQuery(1);

  const formattedCompanyBanks = data?.company_banks?.map((item) => {
    return {
      label: item.bank_name + " | " + item.account_name,
      value: item.bank_id,
    };
  });

  useEffect(() => {
    if (previousData?.company_bank_id && formattedCompanyBanks) {
      // Find the option object corresponding to the company_bank_id
      const selectedOption = formattedCompanyBanks?.find(
        (option) => option.value === previousData.company_bank_id
      );
      setSelectedBank(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousData, data]);

  useEffect(() => {
    if (previousData) {
      // Find the selected option based on is_withdrawable field from previousData
      const selectedOption = previousData.is_withdrawable
        ? { label: "Withdrawable", value: 1 }
        : { label: "Non-Withdrawable", value: 0 };
      setSelectedWithdrawableStatus(selectedOption);
    }
  }, [previousData]);

  useEffect(() => {
    if (previousData && branches) {
      // Extract branch_id from previousData
      const { branch_id } = previousData;

      // Find the corresponding branch object
      const selectedBranch = branches.find((branch) => branch.id === branch_id);

      // If selectedBranch is found, update selectedBranches state
      if (selectedBranch) {
        setSelectedBranches([
          { value: selectedBranch.id, label: selectedBranch.name },
        ]);

        formik.setFieldValue("branches", [{ branch_id }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousData, branches]);

  const handleFormSubmit = async (formikValues) => {
    const updatedValues = {
      ...formikValues,
      amount: parseInt(formikValues.amount),
      company_bank_id: parseInt(formikValues.company_bank_id),
    };

    const response = await handleUpdateContributionCategory(updatedValues);
    if ("error" in response) {
      const { data } = response.error;
      return toast.error(data.message);
    }

    const { message, status } = response.data;
    if (!status) return toast.error(message);
    handleClose();
    toast.success(message);
    formik.resetForm();
  };

  return (
    <div>
      <h3 className="mb-2 text-black1 text-xl pt-2 fw-bold text-center">
        Edit Contribution
      </h3>
      <form className="w-full" onSubmit={formik.handleSubmit}>
        <section className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-10">
          <Input
            type="text"
            label="Contribution name"
            placeholder={"Input contribution name"}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik?.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
          />
          <CustomSelect
            options={branches?.map((branch) => ({
              value: branch.id,
              label: branch.name,
            }))}
            styles={customStyles}
            label={"Branch"}
            isLoading={loadingBranches}
            onChange={(selectedOptions) => {
              setSelectedBranches(selectedOptions);
              const selectedBranchIds = selectedOptions.map(
                (option) => option.value
              );
              formik.setValues({
                ...formik.values,
                branches: selectedBranchIds.map((branch_id) => ({ branch_id })),
              });
            }}
            isMulti
            value={selectedBranches} // Pass the selectedBranches state as value
            placeholder="Select contribution branch"
          />
          {formik?.touched.branches && formik.errors.branches && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {formik.errors.branches}
            </span>
          )}
          <Input
            type="number"
            label="Amount"
            placeholder={"#1,000,000"}
            name="amount"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amount}
            error={formik?.touched.amount && formik.errors.amount}
            errorMessage={formik.errors.amount}
          />
          <Input
            type="number"
            label="Contribution threshold"
            placeholder={"#1,000,000"}
            name="threshold"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.threshold}
            error={formik?.touched.threshold && formik.errors.threshold}
            errorMessage={formik.errors.threshold}
          />
          <Input
            type="date"
            label="End date"
            placeholder={"Select end date"}
            name="end_date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.end_date}
            error={formik?.touched.end_date && formik.errors.end_date}
            errorMessage={formik.errors.end_date}
          />
          <CustomSelect
            options={formattedCompanyBanks}
            styles={customStyles}
            label={"Payment bank"}
            value={selectedBank}
            onChange={(value) => {
              formik.setFieldValue("company_bank_id", value.value);
            }}
            placeholder="Select bank to accept contribution"
          />
          {formik?.touched.bank_name && formik.errors.bank_name && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {formik.errors.bank_name}
            </span>
          )}

          <CustomSelect
            options={[
              {
                label: "Withdrawable",
                value: 1,
              },
              {
                label: "Non-Withdrawable",
                value: 0,
              },
            ]}
            styles={customStyles}
            label={"Withdrawable status"}
            onChange={(selectedOption) => {
              setSelectedWithdrawableStatus(selectedOption);
              formik.setFieldValue("is_withdrawable", selectedOption.value);
            }}
            value={selectedWithdrawableStatus} // Pass the selectedWithdrawableStatus state as value
            placeholder="Select withdrawable status"
          />
          {formik?.touched.bank_name && formik.errors.bank_name && (
            <span className="text-sm text-red-500 font-circular_book block w-full">
              {formik.errors.bank_name}
            </span>
          )}
        </section>
        <div className="my-3 w-full flex justify-center ">
          <button className="w-[200px] h-[50px] rounded-[10px] bg-new-primary text-white">
            {isLoading ? "Updating category..." : "Update category"}
          </button>
        </div>
      </form>
    </div>
  );
}
