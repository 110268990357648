import EditBranch from "./modules/Editbranch";
import { useGetBranchesQuery } from "../../../store/admin/settings/branchSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { PageTitleWithNav } from "../modules/PageHeaders";
export default function SettingEditBranch() {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data = [],
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetBranchesQuery();
  const branchDetails = useMemo(() => {
    const allBranches = [...data];
    const currentBranch = allBranches.find(
      (branch) => branch.id === Number(id)
    );
    return currentBranch;
  }, [data, id]);

  if (isError) {
    if (error.status === 401) {
      return navigate("/login");
    }
    console.log(error);
    return <p>An error occured</p>;
  }

  if ((!isFetching || !isLoading) && !branchDetails) {
    return (
      <section className="bg-white px-2 px-lg-4 min-vh-100 mb-3">
        <div className="mt-10">
          <h1>Branch not found</h1>
        </div>
      </section>
    );
  }

  return (
    <section className="px-3 px-lg-5 min-vh-100 mb-3">
      {isLoading || isFetching ? (
        <p>Loading</p>
      ) : (
        <>
          <PageTitleWithNav
            title={"Edit Branch"}
            highlightText={branchDetails.name}
            action={() => navigate(-1)}
          />
          <EditBranch branchDetails={branchDetails} />
        </>
      )}
    </section>
  );
}
