/* eslint-disable no-unused-expressions */

import { useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { BsDownload, BsPlus, BsPrinter, BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import styledComponents from "styled-components";
import { ButtonComp } from "./ButtonComp";
import { DropDownComp } from "./selectComp";
// import { IconComp } from "../../utils";

const Tabstyle = styledComponents.div`
.line{
    border-bottom: 2px solid ${(props) =>
      props?.BorderBottomColor ? props?.BorderBottomColor : "inherit"};
}
.imageWrapper2{
    width: 40px;
    height:40px;
    border-radius:50%;
    overflow: hidden;
    
}
img{
    width: 100%;
    object-fit: cover;
}

`;

export const Tabs = ({
  TabArray = [],
  tabTitleClassName,
  borderBottomColor,
  TabBody1 = null,
  TabBody2 = null,
  TabBody3 = null,
  handleOuterSelect,
}) => {
  const [tab, setTab] = useState(0);
  return (
    <Tabstyle BorderBottomColor={borderBottomColor}>
      <div className="col-12 overflow-auto">
        <div></div>
        <div className="d-flex w-100  mb-5 border border-top-0 border-end-0 border-start-0 border-bottom-1">
          {TabArray.map((item, index) => (
            <div
              key={index}
              className={`col-6 col-lg-3 pointer text-uppercase text-center  fw-3 pb-2 ${
                tab === index ? "text-black line" : "text-1"
              }`}
              onClick={() => {
                setTab(index);
                handleOuterSelect(index);
              }}
            >
              <div className={`${tabTitleClassName} h50`}>{item?.name}</div>
            </div>
          ))}
        </div>
        <div className="h-30">
          {tab === 0 && <div>{TabBody1}</div>}
          {tab === 1 && <div>{TabBody2}</div>}
          {tab === 2 && <div>{TabBody3}</div>}
          {/* {tab === 0 ? (
           <div> {TabBody1}</div>
          ) :tab === 1  (
            <div>{TabBody2}</div>
          )  ? tab === 3 
          (
            <div>{TabBody3}</div>
          )
          :
          null
          } */}
        </div>
      </div>
    </Tabstyle>
  );
};

export const TabsComp = ({
  TabArray = [],
  lineBackground,
  input = "component",
  tabClassName = "",
  setTabComp = {},
  justifyContent,
  noLine,
  TabArrayClassName = "",
  lineHeight,
  borderWidth = null,
  borderColor = null,
  lineBottom = null,
  lineRight,
  lineLeft,
  row,
  btn,
  btnWrapper,
  btnOnClick,
}) => {
  const [tab, setTab] = useState(0);
  return (
    <Tabstyle2
      LineHeight={lineHeight}
      lineLeft={lineLeft}
      lineRight={lineRight}
      lineBackground={lineBackground}
      BorderWidth={borderWidth}
      BorderColor={borderColor}
      LineBottom={lineBottom}
    >
      <div>
        <div className=" w-full flex justify-between items-center mb-3">
          <div className="position-relative scrollXI">
            <div
              className={`${row ? "row" : "d-flex"} ${
                justifyContent ? justifyContent : "justify-content-between"
              } align-items-center w-100    `}
            >
              {TabArray?.map((item, index) => (
                <div
                  key={index}
                  className={`  pointer  h6  d-inline-block fw2 pb-2 
               ${tabClassName || "col-4 "}`}
                  onClick={() => {
                    setTab(index);
                    typeof setTabComp === "function" ? setTabComp(index) : null;
                  }}
                >
                  <div
                    className={`d-inline-block pb-2 me-3 ${TabArrayClassName}  fw-0 font-1 ${
                      tab === index ? "line" : "text-secondary "
                    } h6   text-center font-2 fw-1 text-nowrap`}
                  >
                    {input ? item[input || "name"] : item||'-'}
                  </div>
                </div>
                // <hr/>
              ))}
            </div>
            {!noLine && <div className="lineHr px-3"></div>}
          </div>
          {btn && (
            <div className={`${btnWrapper}`}>
              <ButtonComp
                btnText={"Record repayment"}
                btnClassName={
                  " py-2 px-4  mb-4 bg-primary1 border-0 text-white rounded  "
                }
                onClick={btnOnClick}
              />
            </div>
          )}
        </div>
        <div>{TabArray[tab]?.component}</div>
      </div>
    </Tabstyle2>
  );
};

export const TopTab = ({ Title, link, addText, isSelected }) => {
  return (
    <div className="d-flex justify-content-between flex-wrap">
      <div className="col-xl-6 mb-3 mb-xl-0">
        <div className="row align-items-center">
          {Title && (
            <div className="h40 col-6 mb-3 mb-lg-0 col-lg-3 col-xl-3">
              {Title}
            </div>
          )}
          <div className="col-10 col-lg-9 col-xl-7">
            <InputGroup className="">
              <InputGroup.Text className="bg-white border-0">
                <BsSearch />
              </InputGroup.Text>
              <FormControl
                aria-label="Amount (to the nearest dollar)"
                placeholder="Search"
                className="border-start-0 border-bottom-0 border-top-0"
              />
              <InputGroup.Text className="bg-white border">
                <DropDownComp
                  dropLabelClassName={"bg-white"}
                  show
                  DropDownText={<span className="pe-4 h50">Sort By</span>}
                />
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="col-xl-6 text-lg-end">
        <div className="d-flex justify-content-lg-end flex-wrap">
          {isSelected && (
            <ButtonComp
              btnText={
                <span>
                  <span className="me-2">
                    <RiDeleteBin6Line />
                  </span>
                  Delete
                </span>
              }
              disabled={true}
              btnClassName={
                "py-2 rounded px-3 bg-red text-white me-3 mb-3 opacity-50"
              }
            />
          )}
          <Button className="me-3 d-flex mb-3" variant="outline-primary">
            Print
            <span className="ms-2">
              <BsPrinter />
              {/* <img src={"/print.svg"} alt="" /> */}
            </span>
          </Button>
          <Button
            className="me-3 d-flex align-items-center mb-3"
            variant="outline-primary"
          >
            Download
            <span className="ms-2">
              <BsDownload />
            </span>
          </Button>
          {addText && (
            <Link
              to={link}
              className="btn-primary d-flex justify-content-center align-items-center rounded px-3 px-lg-4 py-1 mb-3"
            >
              <span className="me-2">
                <BsPlus size={20} />
                {/* <img src={"/plus.svg"} alt="" /> */}
              </span>
              {addText}
            </Link>
          )}
        </div>
      </div>
      {/*  */}
    </div>
  );
};
export const TopTab2 = ({ Title, link, addText, isSelected }) => {
  return (
    <div className="row justify-content-between ">
      <div className="col-xl-4 mb-3 mb-xl-0">
        <div className="row align-items-center">
          {Title && <div className="h40 col-12 mb-3 mb-lg-0 ">{Title}</div>}
        </div>
      </div>
      <div className="col-xl-8 text-lg-end">
        <div className="d-flex justify-content-lg-end flex-wrap">
          <div className="col-4  me-3">
            <DropDownComp
              DropDownText={addText}
              show
              dropLabelClassName={"border ps-1 pe-3 py-2"}
            />
          </div>
          {isSelected && (
            <ButtonComp
              btnText={
                <span>
                  <span className="me-2">
                    <RiDeleteBin6Line />
                  </span>
                  Delete
                </span>
              }
              disabled={true}
              btnClassName={
                "py-2 rounded px-3 bg-red text-white me-3 mb-3 opacity-50"
              }
            />
          )}
          <Button className="me-3 d-flex mb-3" variant="outline-primary">
            Print
            <span className="ms-2">
              <BsPrinter />
              {/* <img src={"/print.svg"} alt="" /> */}
            </span>
          </Button>
          <Button
            className="me-3 d-flex align-items-center mb-3"
            variant="outline-primary"
          >
            Download
            <span className="ms-2">
              <BsDownload />
            </span>
          </Button>
          {/* {addText && <Link
          to={link}
          className="btn-primary d-flex justify-content-center align-items-center rounded px-3 px-lg-4 py-1 mb-3"
        >
            
          <span className="me-2">
          <BsPlus size={20}/>
          </span>
         {addText}
        </Link>
} */}
        </div>
      </div>
      {/*  */}
    </div>
  );
};

export const PageHeader = ({
  link = "#",
  Title,
  subTitle,
  footer,
  PageHeaderClassName,
  PageHeaderContainerClassName,
}) => {
  return (
    <>
      <div
        className={`mt-4 d-flex justify-content-between align-items-center ${PageHeaderContainerClassName}`}
      >
        <h6 className="mb-0">
          <Link to={link} className="text-primary">
            {Title}
          </Link>{" "}
          / <span>{subTitle}</span>
        </h6>
        {footer && (
          <ButtonComp
            btnText={footer}
            btnClassName="bg-blue text-white px-3 py-2 rounded"
          />
        )}
      </div>
      <div
        className={`${
          PageHeaderClassName ? PageHeaderClassName : "py-2 my-3"
        } `}
      ></div>
    </>
  );
};

export const PageTab = ({ back, next, PageArray, input, page }) => {
  // console.log(PageArray, page, "PageArray");
  return <>{PageArray.find((item) => item?.name === page)?.component}</>;
};

const Tabstyle2 = styledComponents.div`
.line{
   border-bottom:${(props) =>
     props?.BorderWidth ? props?.BorderWidth : "5px"} solid ${(props) =>
  props?.BorderColor || "#8B313A"};
    // border-width:
    // border-color:
}
.imageWrapper2{
    width: 40px;
    height:40px;
    border-radius:50%;
    overflow: hidden;
    
}
img{
    // width: 100%;
    object-fit: cover;
}
.lineHr{
  position: absolute;
    left: ${(props) => (props?.lineLeft ? props?.lineLeft : "10px")};
    right: ${(props) => (props?.lineRight ? props?.lineRight : "10px")};
    bottom: ${(props) => (props?.LineBottom ? props?.LineBottom : "22px")};
    width: 100%;
    background: ${(props) =>
      props?.lineBackground ? props?.lineBackground : "#0000002e"};
    height: ${(props) => (props?.LineHeight ? props?.LineHeight : "4.5px")};
    z-index: -1;
}

`;
