import React, { useState } from "react";
// import { BiChevronRight } from "react-icons/bi";
import {
  // ButtonComp,
  FileUploaderComp,
  ImageLoaderUpdate,
  SelectComp2,
  TextInputComp,
  RadioButtonComp,
  CardComp,
} from "../../../../components";
// import FallbackImage from "../../../../components/Common/ImageWrapper";
// import { ButtonComp } from "../../../../components";
import { Formik, Form } from "formik";
import { useAddBranchMutation } from "../../../../store/admin/settings/branchSlice";
import * as Yup from "yup";
import { toast } from "react-hot-toast";

export default function CreateBranch({ onNext }) {
  // eslint-disable-next-line no-unused-vars
  const [addNewBranch, { isError, isLoading, isSuccess }] =
    useAddBranchMutation();
  const [branchLocation, setBranchLocation] = useState();
  const getLocationSelected = (locationSelected) => {
    const location = locationSelected.value;
    setBranchLocation(location);
  };

  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(/^([0]{1})[0-9]{10}$/, "Phone number must be eleven didgits")
      .required("Eleven digits Nigerian phone number required"),
    // email: Yup.string()
    //   .email("Field must be an email")
    //   .required("Email required"),
    address: Yup.string().required("Address is required"),
    name: Yup.string().required("name is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    address: "",
    phone: "",
    location: "",
  };
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const reqBody = {
      ...values,
      branchLocation,
    };

    const response = await addNewBranch(reqBody);
    if ("error" in response) {
      const { data } = response?.error;
      toast.error(data.message);
      return;
    }
    const { message } = response?.data;
    resetForm();
    toast.success(message);
    // setModalName();
    // try {
    //   setSubmitting(true);
    //   const toastId = toast.loading("processing");
    //   const payload = await addNewBranch(reqBody);
    //   // if ("error" in payload) {
    //   //   const { data } = payload?.error;
    //   //   toast.error(data.message);
    //   //   return;
    //   // }
    //   console.log(payload, "ttttt");
    //   if (payload.status === false) {
    //     toast.error(payload.message || "An error occured", {
    //       id: toastId,
    //     });
    //   }
    //   if (payload.status === true) {
    //     toast.success(payload.message || "Success", {
    //       id: toastId,
    //     });
    //     resetForm();
    //   }
    // } catch (error) {
    //   toast.error(error.message || "An error occured");
    // } finally {
    //   setSubmitting(false);
    // }
  };
  return (
    <>
      <div>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form>
            <CardComp
              cardClassName={"pt-4"}
              cardBodyClassName={"py-0 row gx-5 gy-3 justify-content-between"}
            >
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Branch name"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Input branch name"}
                    name="name"
                  />
                </div>
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Phone number"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Enter Phone number"}
                    name="phone"
                  />
                </div>
                {/* <div className="mb-3">
                  <TextInputComp
                    LabelText={"Email address"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Enter Email"}
                    name="email"
                  />
                </div> */}
                {/* <div className="mb-3">
                  <SelectComp2
                    LabelClassName={"fw-2"}
                    labelText={" Location"}
                    arrayComp={[
                      { value: "", label: "Select Location" },
                      {
                        value: "Ogun",
                        label: "Ogun",
                      },
                      {
                        value: "Lagos",
                        label: "Lagos",
                      },
                    ]}
                    setSelectedOption={getLocationSelected}
                  />
                </div> */}
              </div>
              <div className="col-12 col-lg-5">
                <div className="mb-3">
                  <TextInputComp
                    LabelText={"Address"}
                    borderColor="#A1A0A0"
                    LabelClassName={"fw-2"}
                    placeholder={"Enter Address"}
                    name="address"
                  />
                </div>
                {/* <div className="mb-4">
                  <FileUploaderComp
                    LabelText={"Profile Image"}
                    labelClassName={"fw-2"}
                    bodyText={
                      <div className="d-flex align-items-center">
                        <ImageLoaderUpdate
                          src={"/FileUploader/upload.svg"}
                          style={{ width: "60%" }}
                          className="me-3"
                        />

                        <div className="fw-2" style={{ color: "#6B6B6B" }}>
                          Click to Upload Image
                        </div>
                      </div>
                    }
                  />
                </div> */}
                {/* <div>
                  <RadioButtonComp
                    fieldName={"with_logo"}
                    LabelText={
                      <span className="ms-3">use cooperative logo</span>
                    }
                    backgroundColor=" #979797"
                  />
                </div> */}
              </div>
            </CardComp>
            <div className="">
              <button
                className="text-white h-10 bg-[#8B313A] px-4 rounded hover:scale-105 disabled:opacity-50"
                disabled={isLoading}
              >
                {isLoading ? "Creating branch..." : "Create Branch"}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
}
