import { formatMoney } from "./currencyFormat";
export const sideBarData = [
  {
    name: "Dashboard",
    path: "/super_admin/dashboard",
    image1: "/Images/sidebar/Dashboard.svg",
    image: "/Images/sidebar/Dashboard (1).svg",
    dropDown: "none",
  },
  {
    name: "Loan Management",
    path: "/super_admin/loan_management",
    image: "/Images/sidebar/Loan Management.svg",
    image1: "/Images/sidebar/Loan Management1.svg",
    subMenu: [
      {
        name: "New Application",
        path: "/super_admin/loan_management/new_application",
      },
      {
        name: "Loan Type",
        path: "/super_admin/loan_management/loan_type",
      },
      // {
      //   name: "Loan Calculator",
      //   path: "/super_admin/loan_management/loan_calculator",
      // },
      {
        name: "Loan Status",
        path: "/super_admin/loan_management/loan_status",
      },
      {
        name: "Loan Repayment",
        path: "/super_admin/loan_management/loan_repayment",
      },
      // {
      //   name: "Repayment Settings",
      //   path: "/super_admin/loan_management/repayment_settings",
      // },
    ],
  },
  {
    name: "Automated Birthday",
    path: "/super_admin/automated_birthday",
    image: "/Images/sidebar/Automated Birthday.svg",
    image1: "/Images/sidebar/Automated Birthday1.svg",
    dropDown: "none",
  },
  {
    name: "Contribution",
    path: "/super_admin/contribution",
    image: "/Images/sidebar/Contribution.svg",
    image1: "/Images/sidebar/Contribution1.svg",
    subMenu: [
      {
        name: "Contribution Category",
        path: "/super_admin/contribution/contribution-category",
      },
      // {
      //   name: "Create Contribution",
      //   path: "/super_admin/contribution/create_contribution",
      // },
      {
        name: "Contribution History",
        path: "/super_admin/contribution/contribution_history",
      },
      {
        name: "Record Contribution",
        path: "/super_admin/contribution/record_contribution",
      },
      // {
      //   name: "Member Contribution Breakdown",
      //   path: "/super_admin/contribution/record_contribution",
      // },
    ],
  },
  {
    name: "Staff Management",
    path: "/super_admin/staff_registration",
    image: "/Images/sidebar/Staff management.svg",
    image1: "/Images/sidebar/Staff management1.svg",
    subMenu: [
      {
        name: "Staff Registration",
        path: "/super_admin/staff_registration",
      },
      {
        name: "View Staff",
        path: "/super_admin/staff_registration/view_staff",
      },
      {
        name: "Batch Upload",
        path: "/super_admin/staff_registration/batch_upload",
      },
    ],
  },
  {
    name: "Member Management",
    path: "/super_admin/member_management",
    image: "/Images/sidebar/Employee.svg",
    image1: "/Images/sidebar/Employee1.svg",
    subMenu: [
      {
        name: "Member Registration",
        path: "/super_admin/member_management",
      },
      {
        name: "View Member (s)",
        path: "/super_admin/member_management/members",
      },
      {
        name: "Pending Member",
        path: "/super_admin/member_management/pending_member",
      },
      {
        name: "Batch Upload",
        path: "/super_admin/member_management/batch_upload",
      },
    ],
  },

  {
    name: "Communication",
    path: "/super_admin/communication",
    image: "/Images/sidebar/antenna.svg",
    subMenu: [
      {
        name: "Summary",
        path: "/super_admin/communication/summary",
      },
      {
        name: "Group Management",
        path: "/super_admin/communication/group_management",
      },
      {
        name: "Communications",
        path: "/super_admin/communication",
      },
    ],
  },
  {
    name: "Attendance",
    path: "/super_admin/attendance/",
    image: "/Images/sidebar/Note.svg",
    image1: "/Images/sidebar/Note1.svg",
    // dropDown: "none",
    subMenu: [
      {
        name: "Mark Attendance",
        path: "/super_admin/attendance",
      },
      {
        name: "Attendance History",
        path: "/super_admin/attendance/history",
      },
    ],
  },
  {
    name: "Event Calendar",
    path: "/super_admin/event_calendar",
    image: "/Images/sidebar/Calendar.svg",
    image1: "/Images/sidebar/Calendar1.svg",
    dropDown: "none",
  },
  {
    name: "Configuration & Settings",
    path: "/super_admin/settings",
    image: "/Images/sidebar/settings.svg",
    image1: "/Images/sidebar/settings1.svg",
    subMenu: [
      {
        name: "Portal Settings",
        path: "/super_admin/settings/portal_settings",
      },
      {
        name: "Branch Management",
        path: "/super_admin/settings/branch_management",
      },
      {
        name: "Company Prefix",
        path: "/super_admin/settings/company_prefix",
      },
      {
        name: "Society Bank Info",
        path: "/super_admin/settings/society_bank_info",
      },
      {
        name: "Member Registration Fee",
        path: "/super_admin/settings/member_registration_fee",
      },
      {
        name: "Role Management",
        path: "/super_admin/settings/role_management",
      },
      // {
      //   name: "Loan Application Setting",
      //   path: "/super_admin/settings/loan_application_settings",
      // },
      {
        name: "Fine Setting",
        path: "/super_admin/settings/fine_settings",
      },
    ],
  },
  {
    name: "Support",
    path: "/super_admin/admin",
    image: "/Images/sidebar/Call Center.svg",
    dropDown: "none",
  },
  {
    name: "Financial Accounting",
    path: "/super_admin/admin",
    image: "/Images/sidebar/Calculator.svg",
  },
  {
    name: "Dividend Management",
    path: "/super_admin/admin",
    image: "/Images/sidebar/briefcase1.svg",
  },
  {
    name: "E-Minutes",
    path: "/super_admin/admin",
    image: "/Images/sidebar/Timer.svg",
    subMenu: [
      {
        name: "View Minutes",
        path: "/super_admin/eminutes",
      },
      {
        name: "Create Message",
        path: "/super_admin/eminutes/new",
      },
    ],
  },
  {},
];
export const sideBarMemberData = [
  {
    name: "Dashboard",
    path: "/member/dashboard",
    image1: "/Images/sidebar/Dashboard.svg",
    image: "/Images/sidebar/Dashboard (1).svg",
    dropDown: "none",
  },
  {
    name: "Loan Management",
    path: "/member/loan_management",
    image: "/Images/sidebar/Loan Management.svg",
    image1: "/Images/sidebar/Loan Management1.svg",
    dropDown: "none",
    // subMenu:[
    //   {
    //     name:'New Application',
    //     path: "/super_admin/loan_management/new_application",
    //   },
    //   {
    //     name:'Loan Status',
    //     path: "/super_admin/loan_management/loan_status",
    //   },
    //   {
    //     name:'Loan Repayment',
    //     path: "/super_admin/loan_management/loan_repayment",
    //   },
    // ]
  },
  {
    name: "Automated Birthday",
    // path: "/super_admin/automated_birthday",
    image: "/Images/sidebar/Automated Birthday.svg",
    image1: "/Images/sidebar/Automated Birthday1.svg",
    dropDown: "none",
  },
  {
    name: "Contribution",
    path: "/member/contribution",
    image: "/Images/sidebar/Contribution.svg",
    image1: "/Images/sidebar/Contribution1.svg",
    dropDown: "none",
  },

  {
    name: "Attendance",
    path: "/member/attendance",
    image: "/Images/sidebar/Note.svg",
    image1: "/Images/sidebar/Note1.svg",
    dropDown: "none",
  },
  {
    name: "Event Calendar",
    path: "/member/event",
    image: "/Images/sidebar/Calendar.svg",
    image1: "/Images/sidebar/Calendar1.svg",
    dropDown: "none",
  },

  {
    name: "Support",
    // path: "/super_admin/admin",
    image: "/Images/sidebar/Call Center.svg",
    dropDown: "none",
  },

  {
    name: "E-Minutes",
    // path: "/super_admin/admin",
    image: "/Images/sidebar/Timer.svg",
  },
];

export const LoginCarouselData = [
  {
    title: "Collaboration meets Cooperation",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur nunc, erat auctor commodo eu nunc integer nisl arcu. Nunc tempor sit faucibus nibh. Cursus nec placerat urna elementum suscipit arcu purus, feugiat. Viverra eu lacus enim convallis. Integer in metus eget dolor viverra facilisis enim. Non massa tristique bibendum eu condimentum. Eu ullamcorper nulla sit amet mauris.`,
    image: `/Images/Login/Frame 41.svg`,
  },
  {
    title: "Growth meets Dedication",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur nunc, erat auctor commodo eu nunc integer nisl arcu. Nunc tempor sit faucibus nibh. Cursus nec placerat urna elementum suscipit arcu purus, feugiat. Viverra eu lacus enim convallis. Integer in metus eget dolor viverra facilisis enim. Non massa tristique bibendum eu condimentum. Eu ullamcorper nulla sit amet mauris.`,

    image: "/Images/Login/Frame 41-1.svg",
  },
  {
    title: "Success meets Determination   ",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur nunc, erat auctor commodo eu nunc integer nisl arcu. Nunc tempor sit faucibus nibh. Cursus nec placerat urna elementum suscipit arcu purus, feugiat. Viverra eu lacus enim convallis. Integer in metus eget dolor viverra facilisis enim. Non massa tristique bibendum eu condimentum. Eu ullamcorper nulla sit amet mauris.`,
    image: "/Images/Login/Frame 41-2.svg",
  },
];

export const DashboardCards = (dashboardData) => {
  if (!dashboardData) return [];

  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-NG').format(number);
  };

  const formatCurrency = (amount) => {
    return `₦ ${formatNumber(amount)}.00`;
  };

  return [
    {
      name: "Onboarded Members",
      total: dashboardData.onboardedMembers !== undefined && dashboardData.onboardedMembers !== null ? formatNumber(dashboardData.onboardedMembers) : "0",
      image: "/Images/Dashboard/1.svg",
      bgColor: "#A8E1C8",
      color: "#318C64",
      route:"/super_admin/member_management/members"
    },
    {
      name: "Onboarded Staff",
      total: dashboardData.onboardedStaff !== undefined && dashboardData.onboardedStaff !== null ? formatNumber(dashboardData.onboardedStaff) : "0",
      image: "/Images/Dashboard/1.svg",
      bgColor: "#A8D4E1",
      color: "#31778C",
      route:"/super_admin/staff_registration/view_staff"
    },
    {
      name: "Branches",
      total: dashboardData.branches !== undefined && dashboardData.branches !== null ? formatNumber(dashboardData.branches) : "0",
      image: "/Images/Dashboard/1.svg",
      bgColor: "#A8D4E1",
      color: "#31778C",
      route:"/super_admin/settings/branch_management"
    },
    {
      name: "Available Savings",
      total: formatCurrency(dashboardData.availableSavings),
      image: "/Images/Dashboard/2.svg",
      bgColor: "#E0A8AE",
      color: "#8B313A",
      route:"/super_admin/contribution/contribution_history"
    },
    {
      name: "Loan Disbursed",
      total: formatCurrency(dashboardData.loanDisbursed),
      image: "/Images/Dashboard/1.svg",
      bgColor: "#A8D4E1",
      color: "#31778C",
      route:"/super_admin/loan_management/loan_status"
    },
  ];
};



export const ViewMemberCards = () => {
  return [
    {
      name: "Personal savings",
      total: "400",
      image: "/Members/Icon2.svg",
      percentage: "80%",
      bgColor: "#A8E1C8",
      color: "#318C64",
    },
    {
      name: "Contributions",
      total: formatMoney(400 || "0"),
      image: "/Members/Icon2.svg",
      percentage: "80%",
      bgColor: "#E0A8AE ",
      color: "#8B313A",
    },
    {
      name: "Contributions",
      total: formatMoney(400 || "0"),
      image: "/Members/Icon2.svg",
      percentage: "80%",
      bgColor: "#A8D4E1",
      color: "#31778C",
    },
  ];
};
export const ViewMemberDashboardCards = () => {
  return [
    {
      name: "Total Contribution made",
      total: formatMoney(400 || "0"),
      image: "/MemberSide/Icons/dashboard1.svg",
      percentage: "80%",
      bgColor: "#A8E1C8",
      color: "#318C64",
    },
    {
      name: "Loan Balance",
      total: formatMoney(400 || "0"),
      image: "/MemberSide/Icons/dashboard2.svg",
      percentage: "80%",
      bgColor: "#E0A8AE ",
      color: "#8B313A",
    },
  ];
};

export const ColorList = [
  "#8B313A",
  " #31778C",
  "#318C64",
  "#383838",
  "#6C63FF",
  "#384FFF",
  "#FF4539",
  "#DD3629",
  "#BDA9EE",
  "#FFB9B9",
  "#EB9612",
  "#318C64",
];
