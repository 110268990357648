import { BsCalendar2Week, BsThreeDotsVertical } from "react-icons/bs";
import { ButtonComp, DropDownComp, ToolTips } from "../../components";
import { formatMoney } from "../currencyFormat";
import FallbackImage from "../../components/Common/ImageWrapper.jsx";
import moment from "moment";
import { convertToTitleCase } from "../helpers";
import CustomRadioComp from "../../components/Ui/CustomRadioComp";

const colors = ["#A8D4E1", "#A8E1C8", "#E0A8AE"];

export const ViewStaffData = [
  {
    id: "1",
    staffName: "Kolawole Tolu",
    phone: "0813356693",
    name: "Form fee",
    branch: "Asddls ssjjs sjsj",
    status: "Active",
    status1: "Compulsory",
    amount: 400000,
    date: "12-05-2022",
    days: "15days",
    address: "34, Jinadu street, Agege, Lagos",
    email: "oreofecs@uniquecoop.com",
    prefixNumber: "ORF-2022",
    prefixCount: "001",
    role: "Secretary",
    accountNumber: "0814037009",
    accountName: "Ifesowapo C & S",
    desc: "General annual meeting",
    duration: "3 hours",
    venue: "Cooperative secretariat",
  },
  {
    id: "1",
    staffName: "KKOO Tolu",
    phone: "08123784567",
    name: "Form fee",
    branch: "Asddls ssjjs sjsj",
    status: "Active",
    status1: "Compulsory",
    amount: 100000,
    date: "12-05-2022",
    days: "15days",
    address: "34, Jinadu street, Agege, Lagos",
    email: "oreofecs@uniquecoop.com",
    prefixNumber: "ORF-2022",
    prefixCount: "001",
    role: "Secretary",
    accountNumber: "0814037009",
    accountName: "Ifesowapo C & S",
    desc: "General annual meeting",
    duration: "3 hours",
    venue: "Cooperative secretariat",
  },
];

export const ViewStaffHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row, index) => (
        <span className="fw-bold text-black">{index + 1}</span>
      ),
      width: "60px",
    },
    {
      name: "Staff name",
      grow: 2,
      selector: (row) => (
        <span className=" flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={row?.photo}
              alt="profile_img"
            />
          </span>
          {`${row?.first_name}  ${row?.last_name}`}
        </span>
      ),
    },
    {
      name: "Phone number",
      selector: (row) => row?.phone,
      width: "140px",
    },
    {
      name: "Branch(es)",
      grow: 2,
      selector: (row) => (
        <div className="d-flex">
          <div
            style={{ background: "#A8D4E1", color: "#31778C" }}
            className="px-2 py-1 rounded-pill me-2"
          >
            {row?.active_branch?.name}
          </div>
          {row?.branches.length > 1 ? (
            <div
              style={{ background: "#E0A8AE", color: "#8B313A" }}
              className="px-2 py-1 rounded-pill"
            >
              {row?.branches.length} other(s)
            </div>
          ) : row?.branches.length === 1 ? (
            row?.branches.map((item) => {
              return (
                <div
                  style={{ background: "#E0A8AE", color: "#8B313A" }}
                  className="px-2 py-1 rounded-pill"
                >
                  {item.branch.name}
                </div>
              );
            })
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "Staff status",
      width: "120px",
      selector: (row) => (
        <div
          style={{
            background: `${row.is_suspended === 0 ? "#19BC31" : "#FF4343"}`,
          }}
          className="px-2 py-1 rounded-pill text-white"
        >
          {row.is_suspended === 0 ? "Active" : "Suspended"}
        </div>
      ),
    },
    {
      name: "Action",
      width: "80px",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
    },
  ];
};

export const MemberTransactionHistoryData = [
  {
    id: "1",
    con: "Kolawole Tolu",
    amount: 4000,
    branch: "Asddls ssjjs sjsj",
    status: "Successful",
    date: "12-04-2022",
    time: "12:30 PM",
  },
];
export const MemberTransactionHistoryHeader = () => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.id}</span>,
      width: "70px",
    },
    {
      name: "Contribution name",
      selector: (row) => <span>{row?.con}</span>,
    },
    {
      name: "Amount",
      selector: (row) => formatMoney(row?.amount),
    },
    {
      name: "Payment method",
      selector: (row) => <span>{row?.branch}</span>,
    },

    {
      name: "Date & Time",
      selector: (row) => (
        <div className="">
          <div>{row?.time}</div>
          <div>{row?.date}</div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => <div className="">{row?.status}</div>,
    },
  ];
};

export const pendingMemberHeader = (
  setModalToggle,
  setModalPage,
  setMemberId
) => {
  return [
    {
      name: "S/N",
      selector: (row) => (
        <span className="text-muted fw-bold text-black">
          {row.serialNumber}
        </span>
      ),
      width: "70px",
    },
    {
      name: "Member name",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3 ">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={row?.photo}
              alt={row?.name}
              className=""
            />
          </span>
          {row?.name}
        </span>
      ),
    },
    {
      name: "Phone number",
      selector: (row) => row?.phone,
    },
    {
      name: "Branch",
      selector: (row) => (
        <div className="bg-secondary4 p-2 rounded-pill text-secondary2">
          {row?.branch}
        </div>
      ),
    },
    {
      name: "STATUS",
      cell: (row) => (
        <div className="row justify-content-between px-3 w-100 gy-0">
          <div className="col-5 p-0">
            <ButtonComp
              onClick={() => {
                setMemberId(row.id);
                setModalPage(0);
                setModalToggle(true);
              }}
              btnText={"Approve"}
              btnClassName="bg-primary1 w-100 px-3 fw-bold py-3 text-white rounded"
            />
          </div>
          <div className="col-5 p-0">
            <ButtonComp
              onClick={() => {
                setMemberId(row.id);
                setModalPage(1);
                setModalToggle(true);
              }}
              btnText={"Decline"}
              btnClassName="bg-white2 fw-bold w-100 text-black px-3 py-3 rounded"
            />
          </div>
        </div>
      ),
      width: "400px",
    },
  ];
};

export const ContributionHistoryHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },
    {
      name: "Contribution name",
      selector: (row) => (
        <div>
          <div>{row?.name}</div>
          <small className="text-muted">{row?.created_at}</small>
        </div>
      ),
    },
    {
      name: "Total amount",
      selector: (row) => formatMoney(row?.total_amount || "0", true),
    },
    {
      name: "Amount contributed",
      selector: (row) => (
        <span>{formatMoney(row?.amount_contributed || "0", true)}</span>
      ),
    },
    {
      name: "Amount remaining",
      selector: (row) => (
        <div className="">
          {formatMoney(row?.amount_remaining || "0", true)}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};
export const ContributionCategoryHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },
    {
      name: "Contribution name",
      selector: (row) => (
        <div>
          <div>{row?.name}</div>
          <small className="text-muted">{row?.created_at}</small>
        </div>
      ),
    },
    {
      name: "THRESHOLD",
      selector: (row) => formatMoney(row?.target_amount || "0", true),
    },
    {
      name: "BRANCH(S)",
      selector: (row) => row?.branch?.name,
    },
    {
      name: "End date",
      selector: (row) => row?.end_date,
      width: "130px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};

export const ContributionHistoryData = [
  {
    id: "1",
    con: "Secretariat construction",
    amount: 400000000,
    amountCont: 400000000,
    amountRem: 400000000,
    branch: "Asddls ssjjs sjsj",
    status: "Successful",
    date: "12-04-2022",
    time: "12:30 PM",
    createdAt: "Created on 7-12-2022",
    paymentType: "Flutterwave",
  },
  {
    id: 2,
    con: "Secretariat construction",
    amount: 400000000,
    amountCont: 400000000,
    amountRem: 400000000,
    branch: "Asddls ssjjs sjsj",
    status: "Failed",
    date: "12-04-2022",
    time: "12:30 PM",
    createdAt: "Created on 7-12-2022",
    paymentType: "Flutterwave",
  },
];

export const ContributionBreakDownHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row, index) => (
        <span className="text-muted fw-bold">{index + 1}</span>
      ),
      width: "70px",
    },
    {
      name: "Contributors name",
      selector: (row) => (
        <div>{`${row?.member?.first_name}  ${row?.member?.last_name}`}</div>
      ),
    },
    {
      name: "Amount",
      selector: (row) => formatMoney(row?.amount || "0", true),
    },
    {
      name: "Payment method",
      selector: (row) => <span>{row?.payment_type?.name}</span>,
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <div className="">
          <div>{moment(row?.created_at).format("YYYY-MM-DD")}</div>
          <div className="text-secondary ">
            {moment(row?.created_at).format("HH:mm:ss")}
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          className={`${true
            // row?.status?.toLowerCase() === "successful"
              ? "text-success"
              : "text-danger"
          }`}
        >
          {/* {convertToTitleCase(row?.status)} */}
        </div>
      ),
    },
  ];
};

export const LoanStatusHeader = (ButtonArray) => {
  return [
    {
      name: "S/N",
      selector: (_, index) => <span className="text-muted">{index + 1}</span>,
      width: "60px",
    },
    {
      name: "MEMBERS NAME",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={row?.member?.photo}
              alt="img"
            />
          </span>
          {`${row?.member?.first_name} ${row?.member?.last_name} `}
        </span>
      ),
      minWidth: "270px",
      maxWidth: "300px",
    },
    {
      name: "LOAN AMOUNT",
      selector: (row) => formatMoney(row?.amount),
    },
    {
      name: "GUARANTOR",
      selector: (row) => (
        <div className=" bg-[rgba(255,255,255,0.19)] flex flex-wrap gap-3  py-2 items-center">
          {row?.guarantors?.map((item, index) => {
            return (
              <>
                {item.status === "CONFIRMED" ? (
                  <span
                    // onMouseEnter={()=> set}
                    className={`flex items-center ${
                      item.status === "CONFIRMED" ? "opacity-100" : "opacity-0"
                    } opacity-75`}
                  >
                    <span className="me-3 ">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                        alt=""
                        className=""
                      />
                    </span>
                    {item.name}
                  </span>
                ) : (
                  <ToolTips
                    showText={`${item.name} is yet to consent to being the guarantor`}
                    text={
                      <span
                        // onMouseEnter={()=> set}
                        className={`flex items-center ${
                          item.status === "CONFIRMED"
                            ? "opacity-100"
                            : "opacity-0"
                        } opacity-75`}
                      >
                        <span className="me-3 ">
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                            alt=""
                            className=""
                          />
                        </span>
                        {item.name}
                      </span>
                    }
                  />
                )}
              </>
            );
          })}
        </div>
      ),
      minWidth: "230px",
    },
    {
      name: "STATUS",
      selector: (row) => ButtonArray(row),
      width: "270px",
    },
  ];
};

export const LoanRepaymentHeader = (dateClassName = "", TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (_, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },

    {
      name: "MEMBERS NAME",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={row?.member?.photo}
              alt="img"
            />
          </span>
          {`${row?.member?.first_name} ${row?.member?.last_name} `}
        </span>
      ),
      minWidth: "200px",
      maxWidth: "250px",
      wrap: true,
    },
    {
      name: "LOAN AMOUNT",
      selector: (row) => formatMoney(row?.amount),
      minWidth: "200px",
      maxWidth: "250px",
    },
    // {
    //   name: "GUARANTOR",
    //   selector: (row) => (
    //     <span className="flex items-center opacity-75">
    //       <span className="me-3 ">
    //         <img
    //           style={{
    //             width: "30px",
    //             height: "30px",
    //             borderRadius: "50%",
    //             objectFit: "cover",
    //           }}
    //           src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
    //           alt=""
    //           className=""
    //         />
    //       </span>
    //       {row?.staffName}
    //     </span>
    //   ),
    //   // minWidth: "270px",
    //   maxWidth: "300px",
    //   wrap: true,
    // },
    {
      name: "LOAN TYPE",
      selector: (row) => row?.loan_type?.name,
    },
    {
      name: "DUE DATE",
      selector: (row) => {
        const selectedDateStr = row?.due_at;
        const selectedDate = moment(selectedDateStr, "YYYY-MM-DD");
        const today = moment();
        const daysDifference = selectedDate.diff(today, "days");
        const daysDifferenceString = daysDifference.toString();
        const formattedDifference = daysDifferenceString.startsWith("-")
          ? daysDifferenceString.substring(1)
          : daysDifferenceString;
        return (
          <div className="px-3 d-flex align-items-center">
            <span className="me-3">
              <BsCalendar2Week size={25} />
            </span>
            {moment(row?.due_at).format("DD-MMM-YYYY")}
            <div
              className={`ms-3 ${
                dateClassName ? dateClassName : "bg-success2 "
              } py-2 px-3 rounded-pill`}
            >
              {`${formattedDifference} days`}
            </div>
          </div>
        );
      },
      width: "300px",
    },

    {
      name: "ACTION",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};

export const SettingBranchHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Branch name",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
              alt=""
              className=""
            />
          </span>
          {row?.name}
        </span>
      ),
    },
    {
      name: "Address",
      selector: (row) => row?.address,
      width: "300px",
    },
    {
      name: "Phone number",
      selector: (row) => row?.phone,
    },
    {
      name: "Email address",
      selector: (row) => row?.email,

      width: "300px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};

export const SettingCompanyPrefixHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Branch name",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
              alt=""
              className=""
            />
          </span>
          {row?.branchName}
        </span>
      ),
      width: "300px",
    },
    {
      name: "Prefix number",
      selector: (row) => row?.prefixNumber,
    },
    {
      name: "Prefix count",
      selector: (row) => row?.prefixCount,
    },
    {
      name: "Date added",
      selector: (row) => (
        <div className="px-3 d-flex align-items-center">{row?.date}</div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};
export const SettingRoleManagementHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Role name",
      selector: (row) => row?.name,
    },
    {
      name: "Permission allowed",
      selector: (row) => (
        <div className="d-flex flex-wrap gap-2">
          {console.log(row?.modules,'Permission')}
          {row?.modules?.map(({ menu,name }, index) => {
            return (
              <div
                key={name}
                className="rounded-pill p-2 h60"
                style={{ background: `${colors[index % colors.length]}` }}
              >
                {name}
              </div>
            );
          })}
        </div>
      ),
      width: "400px",
    },

    {
      name: "Date added",
      selector: (row) => (
        <div className="px-3 d-flex align-items-center">
          <span className="me-3"></span>
          {row?.date??'N/A'}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};
export const SettingMemberRegistrationFeeHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Amount",
      selector: (row) => formatMoney(row?.amount),
    },
    {
      name: "Status",
      selector: (row) => row?.category?.name,
    },

    {
      name: "Date added",
      selector: (row) => (
        <div className="px-3 d-flex align-items-center">
          <span className="me-3"></span>
          {row?.date}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};
export const SettingSocietyBankInformationHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Bank name",
      selector: (row) => row?.bank_name,
    },
    {
      name: "Account name",
      selector: (row) => row?.account_name,
    },
    {
      name: "Account number",
      selector: (row) => row?.account_number,
    },

    {
      name: "Date added",
      selector: (row) => (
        <div className="px-3 d-flex align-items-center">
          <span className="me-3"></span>
          {row?.date}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};
export const EventManagementHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Event name",
      selector: (row) => row?.name,
    },
    {
      name: "Description",
      selector: (row) => <div className="text-wrap">{row?.description}</div>,
      width: "250px",
    },
    {
      name: "Start date",
      selector: (row) => row?.start_date,
      width: "130px",
    },

    {
      name: "End date",
      selector: (row) => row?.end_date,
      width: "130px",
    },
    {
      name: "Duration",
      selector: (row) => <span>{row?.duration} hours</span>,
    },
    {
      name: "Venue",
      selector: (row) => <div className="text-wrap">{row?.address}</div>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const AttendanceHeader = (
  attendanceMarker,
  attendance_statuses = []
) => {
  return [
    {
      name: "S/N",
      selector: (row) => (
        <div className="p-2 text-center">
          <span className="text-muted pl-2 w-100">{row?.serialNumber}</span>
        </div>
      ),
      width: "70px",
    },
    {
      name: "Member name",
      selector: (row) => (
        <span style={{ paddingLeft: "10px" }} className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={row?.photo}
              alt={row?.name}
              className=""
            />
          </span>
          {row?.name}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Attendance status",
      selector: (row) => (
        <div className="d-flex">
          {attendance_statuses.map((attendance_status) => {
            return (
              <div
                className="border-end border-2 p-3"
                key={attendance_status?.name}
              >
                <CustomRadioComp
                  name={row?.id}
                  value={attendance_status?.id}
                  labelText={attendance_status?.name}
                  onValueChange={() =>
                    attendanceMarker(row?.id, attendance_status?.id)
                  }
                />
              </div>
            );
          })}
        </div>
      ),
    },
  ];
};
export const AttendanceHistoryHeader = (single = false) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: single ? "Month" : "Member name",
      selector: (row) => (
        <span className="flex items-center">
          {single ? (
            <span>{row?.month}</span>
          ) : (
            <>
              <span className="me-3">
                <img
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={row?.member?.photo}
                  alt={row?.member?.first_name}
                  className=""
                />
              </span>
              {row?.member?.first_name} {row?.member?.last_name}
            </>
          )}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Attendance status",
      cell: ({ attendanceStatus = [], viewAll }) => (
        <div className="d-flex align-items-center gap-2 justify-content-between w-100">
          <div className="d-flex gap-2 justify-content-between">
            {attendanceStatus.map((item, index) => {
              return (
                <div className="text-center" key={index}>
                  <div className="h60 fw-3">
                    {item?.week_day}-{item.date}
                  </div>
                  <div>
                    <FallbackImage src={item?.icon} />
                  </div>
                </div>
              );
            })}
          </div>
          {single === false && (
            <ButtonComp
              btnText={"View all"}
              btnClassName={"px-5 py-3 h6 text-white bg-primary1 rounded"}
              onClick={() => viewAll()}
            />
          )}
        </div>
      ),
      minWidth: "400px",
    },
    {
      name: "Attendance count",
      selector: ({ attendanceCount }) => (
        <div className="d-flex">
          <div className="d-flex align-items-center me-3">
            Present{" "}
            <div
              style={{ height: "24px", width: "24px", background: "#19BC31" }}
              className="ms-2 d-flex justify-content-center align-items-center bg rounded-circle text-white"
            >
              {attendanceCount?.present}
            </div>
          </div>
          <div className="d-flex align-items-center">
            Absent{" "}
            <div
              style={{ height: "24px", width: "24px", background: "#FF4343" }}
              className="ms-2 d-flex justify-content-center align-items-center bg rounded-circle text-white"
            >
              {attendanceCount?.absent}
            </div>
          </div>
        </div>
      ),
    },
  ];
};

export const MemberLoanStatusHeader = () => {
  return [
    {
      name: "S/N",
      selector: (_, index) => <span>{index + 1}</span>,
      width: "70px",
    },
    {
      name: "Loan name",
      selector: (row) => <span>{row?.loan_type?.name}</span>,
      width: "200px",
    },
    {
      name: "Loan amount",
      selector: (row) => (
        <span className="">{formatMoney(row.amount || "00")}</span>
      ),
    },
    {
      name: "GUARANTOR",
      selector: (row) => (
        <div className=" bg-[rgba(255,255,255,0.19)] flex flex-wrap gap-3  py-2 items-center">
          {row?.guarantors?.map((item, index) => {
            return (
              <>
                {item.status === "CONFIRMED" ? (
                  <span
                    // onMouseEnter={()=> set}
                    className={`flex items-center ${
                      item.status === "CONFIRMED" ? "opacity-100" : "opacity-0"
                    } opacity-75`}
                  >
                    <span className="me-3 ">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                        alt=""
                        className=""
                      />
                    </span>
                    {item.name}
                  </span>
                ) : (
                  <ToolTips
                    showText={`${item.name} is yet to consent to being the guarantor`}
                    text={
                      <span
                        // onMouseEnter={()=> set}
                        className={`flex items-center ${
                          item.status === "CONFIRMED"
                            ? "opacity-100"
                            : "opacity-0"
                        } opacity-75`}
                      >
                        <span className="me-3 ">
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                            alt=""
                            className=""
                          />
                        </span>
                        {item.name}
                      </span>
                    }
                  />
                )}
              </>
            );
          })}
        </div>
      ),
      minWidth: "230px",
    },
    {
      name: "Repayment plan",
      selector: (row) => <span>{row?.repayment_plan}</span>,
      minWidth: "150px",
    },
    {
      name: "Date",
      selector: (row) => (
        <span>{moment(row?.updated_at).format("DD-MMM-YYYY")}</span>
      ),
    },
  ];
};

export const MemberLoanRepaymentHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (_, index) => <span className="text-muted">{index + 1}</span>,
      width: "70px",
    },
    {
      name: "Loan name",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
              alt=""
              className=""
            />
          </span>
          {row?.staffName}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Loan amount",
      selector: (row) => (
        <span className="">{formatMoney(row.amount || "00")}</span>
      ),
    },
    {
      name: "GUARANTOR",
      selector: (row) => (
        <div className=" bg-[rgba(255,255,255,0.19)] flex flex-wrap gap-3  py-2 items-center">
          {row?.guarantors?.map((item, index) => {
            return (
              <>
                {item.status === "CONFIRMED" ? (
                  <span
                    // onMouseEnter={()=> set}
                    className={`flex items-center ${
                      item.status === "CONFIRMED" ? "opacity-100" : "opacity-0"
                    } opacity-75`}
                  >
                    <span className="me-3 ">
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                        alt=""
                        className=""
                      />
                    </span>
                    {item.name}
                  </span>
                ) : (
                  <ToolTips
                    showText={`${item.name} is yet to consent to being the guarantor`}
                    text={
                      <span
                        // onMouseEnter={()=> set}
                        className={`flex items-center ${
                          item.status === "CONFIRMED"
                            ? "opacity-100"
                            : "opacity-0"
                        } opacity-75`}
                      >
                        <span className="me-3 ">
                          <img
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            src="https://i.pinimg.com/originals/42/ee/db/42eedb02a8e9772664170124784428f6.jpg"
                            alt=""
                            className=""
                          />
                        </span>
                        {item.name}
                      </span>
                    }
                  />
                )}
              </>
            );
          })}
        </div>
      ),
      minWidth: "230px",
    },
    {
      name: "Repayment plan",
      selector: (row) => (
        <span className="h50 d-flex  align-items-center">
          <div>
            <FallbackImage src="/MemberSide/Icons/calendar1.svg" />
          </div>
          <div>12-05-2022</div>
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const MemberLoanRepaymentHeader2 = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.id}</span>,
      width: "70px",
    },
    {
      name: "Loan name",
      selector: (row) => <span style={{ width: "50px" }}>Personal Loan</span>,
      width: "200px",
    },
    {
      name: "Loan amount",
      selector: (row) => (
        <span className="">{formatMoney(row.amount || "00")}</span>
      ),
    },
    {
      name: "Guarantor’s",
      selector: (row) => (
        <div className="d-flex gap-3 h50">
          <div>Adebayo Salako,</div> <div>Adebayo Salako</div>
        </div>
      ),
      width: "300px",
    },
    {
      name: "Repayment plan",
      selector: (row) => (
        <span className="h50 d-flex  align-items-center">
          <ButtonComp
            btnText={"Pay now"}
            btnClassName="bg-primary1 text-white px-3 rounded py-2"
          />
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const MemberContributionsHistory = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.id}</span>,
      width: "70px",
    },
    {
      name: "Contribution name",
      selector: (row) => <span style={{ width: "50px" }}>{row?.name}</span>,
      width: "200px",
    },
    {
      name: "Loan amount",
      selector: (row) => (
        <span className="">{formatMoney(row.amount || "00")}</span>
      ),
    },
    {
      name: "Payment method",
      selector: (row) => (
        <div className="d-flex gap-3 h50">
          {row?.paymentType || "FlutterWave"}
        </div>
      ),
      // width:'300px'
    },
    {
      name: "Date & Time",
      selector: (row) => (
        <span className="h50">
          <div>{row?.date}</div>

          <div>{row?.time || "12:30 PM"}</div>
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span className="h50 text-success1">{"Successful"}</span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const MemberUpcomingContributions = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.id}</span>,
      width: "70px",
    },
    {
      name: "Contribution name",
      selector: (row) => <span style={{ width: "50px" }}>{row?.name}</span>,
      width: "200px",
    },
    {
      name: "Loan amount",
      selector: (row) => (
        <span className="">{formatMoney(row.amount || "00")}</span>
      ),
    },
    {
      name: "Amount paid",
      selector: (row) => <span className="">{formatMoney("00")}</span>,
    },

    {
      name: "Due date",
      selector: (row) => (
        <span className="h50">
          <div>{row?.date}</div>
        </span>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span className="h50 d-flex  align-items-center">
          <ButtonComp
            btnText={"Pay now"}
            btnClassName="bg-primary1 text-white px-3 rounded py-2"
          />
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const MemberEventHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <p className="text-muted">{row.id}</p>,
      width: "70px",
    },
    {
      name: "Event name",
      selector: (row) => <p>{row?.name}</p>,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => <p className="">{row?.description}</p>,
      width: "250px",
    },

    {
      name: "Start date",
      selector: (row) => (
        <div className="h50">
          <p>{row?.start_date}</p>
        </div>
      ),
    },

    {
      name: "End date",
      selector: (row) => (
        <div className="h50">
          <p>{row?.end_date}</p>
        </div>
      ),
    },
    {
      name: "Duration",
      cell: (row) => (
        <div className="h50 text-wrap text-center">
          <p>{row?.duration}</p>
        </div>
      ),
      width: "50px",
    },
    {
      name: "Venue",
      selector: (row) => (
        <div className="h50 text-wrap">
          <p className="">{row?.address}</p>
        </div>
      ),
      minWidth: "200px",
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const MemberAttendanceHistoryHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.id}</span>,
      width: "70px",
    },
    {
      name: "Meeting name",
      selector: (row) => (
        <span className="" style={{ width: "50px" }}>
          {"Annual meeting"}
        </span>
      ),
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => <span className="text-wrap">{row?.desc}</span>,
    },

    {
      name: "Status",
      selector: (row) => (
        <span className="h50">
          <div>
            <span>
              <FallbackImage src="/Icon/tick-circle-success.svg" />
            </span>{" "}
            <span className="h60">Present and early</span>
          </div>
        </span>
      ),
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};
export const SettingFineSettingHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.id}</span>,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row?.role,
    },
    {
      name: "Amount",
      selector: (row) => <div className="">{formatMoney(row?.amount)}</div>,
    },

    {
      name: "Fine waiver",
      selector: (row) => <div className="">{"Admins"}</div>,
    },
    {
      name: "Date added",
      selector: (row) => <div className="">{row?.date}</div>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};

export const ViewMembersHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => (
        <span className="fw-bold text-black">{row.serialNumber}</span>
      ),
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => (
        <span className="flex items-center">
          <span className="me-3">
            <img
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              src={row?.photo}
              alt=""
              className=""
            />
          </span>
          {row?.name}
        </span>
      ),
    },
    {
      name: "Phone number",
      selector: (row) => row?.phone,
    },
    {
      name: "Branch",
      selector: (row) => (
        <div className="d-flex center">
          <div
            style={{ background: "#A8D4E1", color: "#31778C" }}
            className="px-2 py-1 rounded-pill me-2"
          >
            {row?.branch}
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <div
          style={{
            backgroundColor: row?.is_suspended ? "#FF4343" : "#19BC31",
          }}
          className="px-2 py-1 rounded-pill text-white"
        >
          {row?.is_suspended ? "Not Active" : "Active"}
        </div>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },

    // {
    //   name: "",
    //   selector: (row) => <div onClick={()=>router.push(`/sales/${type}/${row.id}`)}><BsThreeDots size={25}/></div>,
    //   width:'70px'
    // },
  ];
};

export const EminutesHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => <span className="text-muted">{row.serialNumber}</span>,
      width: "70px",
    },
    {
      name: "Minutes title",
      selector: (row) => <span style={{ width: "50px" }}>{row?.title}</span>,
      width: "300px",
    },
    {
      name: "Date",
      selector: (row) => (
        <div className="px-3 d-flex align-items-center">{row?.date}</div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          style={{ color: row?.status === "PUBLISHED" ? "#19BC31" : "#FF4343" }}
        >
          {row?.status}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown(row)}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};

export const CommunicationGroupListHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => (
        <span className="fw-bold text-black">{row.serialNumber}</span>
      ),
      width: "70px",
    },
    {
      name: "Group name",
      cell: (row) => <span>{row?.name}</span>,
    },
    {
      name: "No. of members",
      selector: (row) => row?.totalMembers,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <DropDownComp
            dropLabelClassName={"bg-white border-0 px-3 py-1 rounded-4 text-2"}
            arrayComp={TableDropDown}
            ItemClassName="pe-5 text-2"
            input="name"
            row={row}
            DropDownText={
              <span>
                <BsThreeDotsVertical size={20} />
              </span>
            }
          />
        </div>
      ),
      width: "100px",
    },
  ];
};

export const CommunicationGroupMembersHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => (
        <span className="fw-bold text-black">{row.serialNumber}</span>
      ),
      width: "70px",
    },
    {
      name: "Member name",
      cell: (row) => (
        <span>
          {row?.member?.first_name} {row?.member?.other_name}{" "}
          {row?.member?.last_name}
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="text-center">
          <img
            src="/Icon/delete2.svg"
            alt="remove"
            role="button"
            onClick={() => TableDropDown(row?.member?.id)}
          />
        </div>
      ),
      width: "100px",
    },
  ];
};
export const CommunicationMessagesHeader = (TableDropDown) => {
  return [
    {
      name: "S/N",
      selector: (row) => (
        <span className="fw-bold text-black">{row.serialNumber}</span>
      ),
      width: "70px",
    },
    {
      name: "Message header",
      cell: (row) => <span>{row?.header}</span>,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div className="text-center">
    //       <img
    //         src="/Icon/delete2.svg"
    //         alt="remove"
    //         role="button"
    //         onClick={() => TableDropDown(row?.member?.id)}
    //       />
    //     </div>
    //   ),
    //   width: "100px",
    // },
  ];
};
