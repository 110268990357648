import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styled from "styled-components";
// import { CalendarComponent } from '@syncfusion/ej2-react-calendars';
export default function CalendarComp({ outLine, height }) {
  return (
    <CalendarStyle Height={height}>
      <div className={`${outLine ? "" : "Wrapper"}  font-1`}>
        <Calendar selectRange={false} />
        {/* value={[new Date(2023, 1, 1), new Date(2023, 1, 4)]} */}
      </div>
    </CalendarStyle>
  );
}
// export default function CalendarComp({outLine,height}) {
//   return (
//     <div Height={height}>
//              <CalendarComponent></CalendarComponent>

//     </div>
//   )
// }

const CalendarStyle = styled.div`
  display: flex;
  justify-content: center;
  .Wrapper {
    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.05),
      2px 2px 10px rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 20px;
    display: inline-block;
  }
  .react-calendar__tile--now {
    background: #8b313a;
    color: white;
  }
  .react-calendar {
    border: none;
    width: auto !important;
    height: ${(props) => (props?.Height ? props?.Height : "100%")};
  }
  .react-calendar__month-view__weekdays div abbr {
    text-decoration: none !important;
    font-family: "Work Sans", sans-serif !important;
  }
  .react-calendar__month-view__days {
    font-family: "Work Sans", sans-serif !important;
    font-size: 16px;
  }
`;
