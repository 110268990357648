// import AdminLayout from "../../../Layout/AdminLayout";
import { Formik, Form } from "formik";
import PersonalDetails from "./modules/PersonalDetails";
import { CardComp, ButtonComp } from "../../../components";
import { useViewMemberQuery } from "../../../store/admin/memberManagement/memberSlice";
import { useParams, useNavigate } from "react-router-dom";
import useMemberRegAction from "./useMemberRegAction";
import { useState } from "react";
import uploadImage from "../../../utils/uploadImage";
import { PageTitleWithNav } from "../modules/PageHeaders";
import Loader from "../../../components/Ui/Loader";

const UpdateMember = () => {
  const navigate = useNavigate();
  const { updateMember } = useMemberRegAction();
  const { id } = useParams();
  const { data: memberData = {}, isFetching } = useViewMemberQuery(id);
  const [imgFile, setImgFile] = useState();
  const [errorImg, setErrorImg] = useState("Click to upload image");

  const updateImgFile = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setImgFile(null);
      return setErrorImg("Upload an image");
    }
    const size = file?.size / 1024 / 1024;
    const type = file?.type?.split("/")[1];
    const validTypes = ["jpeg", "jpg", "png"];
    if (size > 10 || !validTypes.includes(type)) {
      setErrorImg(
        "Invalid. File size must not be more than 10mb\nFile must me either jpg jpeg png"
      );
    } else {
      setErrorImg(null);
      setImgFile(file);
    }
  };

  const initialValues = {
    first_name: memberData?.first_name || "",
    last_name: memberData?.last_name || "",
    other_name: memberData?.other_name || "",
    member_no: memberData?.member_no || "",
    phone: memberData?.phone || "",
    photo: memberData?.photo || "",
    email: memberData?.email || "",
    member_id: memberData?.member_id || "",
    address: memberData?.address || "",
    marital_id: memberData?.marital_id || "",
    gender_id: memberData?.gender_id || "",
    title_id: memberData?.title_id || "",
    dob: memberData?.dob || "",
    occupation: memberData?.occupation || "",
    next_of_kin_name: memberData?.next_of_kin_name || "",
    next_of_kin_phone: memberData?.next_of_kin_phone || "",
    relationship_status_id: memberData?.relationship_status_id || "",
  };
  const handleFormSubmit = async (formikValues) => {
    const reqData = {
      ...formikValues,
      id: id,
      branch_id: memberData?.branch_id,
    };
    if (imgFile) {
      reqData["photo"] = await uploadImage(imgFile);
    }
    updateMember(reqData).finally(() => {});
  };
  return (
    <section className="px-lg-3 min-vh-100 pb-5">
      {isFetching ? (
        <div className="bg-white">
          <Loader />
        </div>
      ) : (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          <Form>
            <PageTitleWithNav
              title={"Edit"}
              highlightText={`${memberData?.first_name || ""} ${
                memberData?.last_name || ""
              }`}
              action={() => navigate(-1)}
            />

            <CardComp>
              <div className="py-4"></div>
              <PersonalDetails
                imgFile={imgFile}
                errorImg={errorImg}
                updateImgFile={updateImgFile}
              />
            </CardComp>
            <div className="">
              <ButtonComp
                btnText={"Proceed"}
                btnClassName={
                  "bg-primary1 py-2 px-4 text-white fw-semibold border-0 rounded"
                }
                type={"submit"}
              />
            </div>
          </Form>
        </Formik>
      )}
    </section>
  );
};

export default UpdateMember;
