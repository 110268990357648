import React from "react";
// import { ContainerComp, DetailsCard, SesaCard } from "../../../components";
// import { PieChartComp } from "../../../components/Charts/PieChart";
// import AdminLayout from "../../../Layout/AdminLayout";
// import { BsDot } from "react-icons/bs";
import styled from "styled-components";
import HomeComp from "./components/Home";

const HomeStyled = styled.div`
  // @media (min-width: 992px){
  // .col-lg-9 {

  //     width: 70% !important;
  // }
  // .col-lg-3 {

  //   width: 30% !important;
  // }
  // }
`;

export const Home = () => {
  return (
    <HomeStyled>
      <HomeComp />
    </HomeStyled>
  );
};
