// import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  // Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";

const data = [
  {
    name: "JAN",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "FEB",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "MAR",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "APR",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "MAY",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "JUN",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "JULY",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "AUG",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "SEP",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "OCT",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "NOV",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "DEC",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const BarChartStyle = styled.span`
  .recharts-legend-wrapper,
  .recharts-cartesian-grid-horizontal,
  .recharts-cartesian-grid-vertical,
  .recharts-cartesian-axis-line,
  .recharts-layer line {
    display: none;
  }
  .recharts-cartesian-axis-ticks {
    font-size: 14px;
  }
`;

export const BarChartComp = ({ chartData }) => {
  return (
    <BarChartStyle>
      <div style={{ width: "100%", height: 250 }}>
        <ResponsiveContainer>
          <BarChart
            width={500}
            height={250}
            data={chartData ?? data}
            margin={{
              top: 5,
              right: 10,
              left: 0,
              bottom: 5,
            }}
            barSize={10}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8B313A" />
            <Bar dataKey="uv" fill="#82C1D3" />
            <Bar dataKey="credit" fill="#8B313A" />
            <Bar dataKey="debit" fill="#82C1D3" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </BarChartStyle>
  );
};
