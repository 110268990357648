import { TableCompData } from "../../../../components";
import EmptyTable from "../../../../components/Common/EmptyTable";
import {
  SettingRoleManagementHeader,
  // ViewStaffData,
} from "../../../../utils/TableHeader";
import { useGetRolesQuery } from "../../../../store/admin/settings/roleSlice";
import { useMemo } from "react";
import { dateFromString } from "../../../../utils";

export default function AllRoleManagement({
  TableDropDown,
  onSubmit,
  emptyTable,
}) {
  const { data = [], error, isFetching } = useGetRolesQuery();
  const tableData = useMemo(() => {
    const items = data?.map((item, index) => {
      return {
        ...item,
        date: dateFromString(item?.created_at),
        serialNumber: index + 1,
      };
    });
    return items;
  }, [data]);
  if (error) {
    console.log(error);
  }

  console.log(tableData,'tableDatatableData')

  return (
    <div>
      <div className="mb-5 pb-10">
        <TableCompData
          marginBottom={"10"}
          columns={SettingRoleManagementHeader(TableDropDown)}
          // data={ViewStaffData}
          data={tableData}
          message={
            isFetching ? (
              <p className="py-4">Loading...</p>
            ) : (
              <EmptyTable
                Message={emptyTable || "No prefix has been created"}
                btnText={"Create new"}
                onClick={onSubmit}
              />
            )
          }
        />
      </div>
    </div>
  );
}
