import React from "react";
import { ButtonComp } from "../Ui";
import FallbackImage from "./ImageWrapper";

export default function EmptyTable({ Message, btnText, onClick }) {
  return (
    <div className="w-full  flex justify-center items-center">
      <div className="flex flex-col space-y-3 justify-center">
        <div className="py-4"></div>
        <div className="flex justify-center">
          <FallbackImage src="/Icon/empty.png" width={100} height={100} />
        </div>
        <div className="text-center text-black1 ">{Message}</div>
        <div className="">
          {btnText && (
            <div className="col-12 flex justify-center">
              <div className="d-flex justify-center">
                <ButtonComp
                  btnText={btnText}
                  onClick={onClick}
                  btnClassName="bg-primary1  py-2 px-4 rounded text-white border-0"
                />
              </div>
            </div>
          )}
        </div>
        <div className="py-5"></div>
      </div>
    </div>
  );
}
