import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { PageSwitch } from "../../../components";
import AllBranch from "./modules/AllBranch";
import CreateBranch from "./modules/CreateBranch";
// import { ButtonComp } from "../../../components";
import {
  useGetBranchesQuery,
  useDeleteBranchMutation,
} from "../../../store/admin/settings/branchSlice";
import { toast } from "react-hot-toast";
import { PageTitle } from "../modules/PageHeaders";
export default function SettingBranch() {
  const { isError, error } = useGetBranchesQuery();
  // eslint-disable-next-line no-unused-vars
  const [deleteBranch, { isError: isErrorDeleting, isLoading: isDeleting }] =
    useDeleteBranchMutation();
  const [pageName, setPageName] = useState("Branch Management");
  const navigate = useNavigate();
  const location = useLocation();

  const removeBranch = async (branchId) => {
    try {
      const toastId = toast.loading("Deleting");
      const payload = await deleteBranch(branchId);
      const { data } = payload;
      if (data.status === false) {
        toast.error(data.message || "An error occured", {
          id: toastId,
        });
      }
      if (data.status === true) {
        toast.success(data.message || "Success", {
          id: toastId,
        });
      }
    } catch (error) {
      toast.error(error.message || "An error occured");
    }
  };

  const TableDropDown = [
    {
      name: "Edit",
      action: (data) => {
        navigate(`${location?.pathname}/${data?.branchId}`);
      },
    },
    {
      name: "Delete",
      action: (data) => {
        removeBranch(data.branchId);
        navigate(`${location?.pathname}`);
      },
    },
  ];

  // const usePage = [
  //   {
  //     name: "Branch Management",
  //     component: (
  //       <AllBranch
  //         TableDropDown={TableDropDown}
  //         onSubmit={() => setPageName(Create Branches"")}
  //       />
  //     ),
  //   },
  //   {
  //     name: "Create Branches",
  //     component: <CreateBranch onNext={() => {}} />,
  //   },
  // ];
  if (isError) {
    if (error.status === 401) {
      return navigate("/");
    }
    console.log(error);
  }

  const pageContent =
    pageName === "Branch Management" ? (
      <div>
        <div className="mb-4 text-end">
          <button
            className="bg-[#8B313A] h-10 rounded px-4 text-white hover:scale-105"
            onClick={() => setPageName("Create Branches")}
            btnClassName="  py-2 px-4 rounded text-white border-0 !bg-primary"
          >Add New</button>
        </div>

        <PageTitle title={pageName} />
        <AllBranch
          TableDropDown={TableDropDown}
          onSubmit={() => setPageName("Create Branches")}
        />
      </div>
    ) : (
      <>
        <div className="mb-4 text-end">
          {/* <ButtonComp
            btnText={"View Branches"}
            onClick={() => setPageName("Branch Management")}
            btnClassName="bg-primary1  py-2 px-4 rounded text-white border-0"
          /> */}
          <button
            className="h-10 text-white rounded px-4 hover:scale-105 bg-new-primary"
            onClick={() => setPageName("Branch Management")}
          >
            View Branches
          </button>
        </div>
        <PageTitle title={pageName} />
        <CreateBranch onNext={() => {}} />
      </>
    );

  return (
    <section className="px-4 px-lg-4 min-vh-100">
      <div className="py-1"></div>
      {pageContent}
      {/* <PageSwitch pageArray={usePage} pageName={pageName} /> */}
    </section>
  );
}
