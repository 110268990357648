import React from "react";

export const CardComp = ({ children, cardClassName, cardBodyClassName }) => {
  return (
    <div
      className={`card px-3 mb-3 pb-4 ${cardClassName}`}
      style={{ border: "none" }}
    >
      <div className={`card-body ${cardBodyClassName}`}>{children}</div>
    </div>
  );
};
