import { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { convertToHTML } from "draft-convert";
import styled from "styled-components";
import { TextInputComp } from "../../../components";

const TextEditor = ({ initialValue = "", handleContentChange }) => {
  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(initialValue))
    )
  );
  // const toolbarOptions = {
  //   options: ["inline", "fontSize", "image", "emoji"],
  //   inline: {
  //     options: ["bold", "italic", "underline", "strikethrough"],
  //   },
  // };
  useEffect(() => {
    handleContentChange(convertToHTML(editorState.getCurrentContent()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);
  return (
    <Wrapper>
      <div className="editor-box">
        <div className="xyz mb-3">
          {/* <h5 className="mb-0 fw-bold">
            Uniquecoop win Best Cooperative Software award
          </h5> */}
          <div className="border rounded-lg  p-2">
          <input
          value={title}
          className="w-full font-bold bg-transparent text-[18px] !outline-none !border-none"
          placeholder={'Uniquecoop win Best Cooperative Software award'}
          onChange={(e) => setTitle(e.target.value)}
          />
          </div>
        </div>
        <Editor
          editorState={editorState}
          onEditorStateChange={(editorState) => setEditorState(editorState)}
          wrapperClassName="editor-container"
          editorClassName="editor-textarea"
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.article`
  .editor-box {
    padding: 1.5rem 1.5rem;
    background-color: white;
  }
  .editor-container,
  .xyz {
    box-sizing: border-box;
    border: 1px solid #adadad;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  .rdw-block-wrapper,
  .rdw-fontfamily-wrapper,
  .rdw-fontsize-wrapper {
    display: none;
  }
  .editor-textarea {
    min-height: 295px;
  }
`;

export default TextEditor;
